import { SimpleInput } from '@integration-app/react'

import { ConfigCard } from 'components/Card/ConfigCard'

import { useGenericConfig } from '../../../../../../../components/common-configs/contexts/generic-config-context'

export function ScheduleTrigger() {
  const { config, patchConfig } = useGenericConfig()

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>Schedule</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content>
        <SimpleInput
          label='Interval (minutes)'
          value={config.intervalMinutes}
          onChange={(intervalMinutes) => patchConfig({ intervalMinutes })}
        />
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}
