import { makeDataField, DataBuilderForm } from '@integration-app/react'
import { Flow, getUpstreamNodeKeys } from '@integration-app/sdk'

import { ConfigCard } from 'components/Card/ConfigCard'
import { useFlowNode } from 'components/FlowBuilder/context/flow-node-context'
import { isTriggerNode } from 'components/FlowBuilder/isTriggerNode'
import { useGenericFlow } from 'components/FlowBuilder/useGenericFlow'
import SimpleSelect from 'components/SimpleSelect'
import { Text } from 'ui-kit/text'

import { useGenericConfig } from '../../../../../../../components/common-configs/contexts/generic-config-context'

export function ForEachV2() {
  const { nodeKey } = useFlowNode()
  const { flow } = useGenericFlow()
  const { patchConfig, config, variablesSchema } = useGenericConfig()

  const allowedSubFlowNodes = getAllowedNodeAsSubFlowRoot(flow, nodeKey)

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>For Each</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content>
        <SimpleSelect
          name='Root Node Key'
          value={config.rootNodeKey}
          options={allowedSubFlowNodes.map((nodeKey) => ({
            label: nodeKey,
            value: nodeKey,
          }))}
          onChange={(rootNodeKey) => patchConfig({ rootNodeKey })}
        />

        <Text>
          Please select or construct a list to iterate over. Following nodes
          will be executed for each item in this list.
        </Text>

        <DataBuilderForm
          onChange={(items) => patchConfig({ items })}
          field={makeDataField({
            schema: { type: 'array', title: 'Items' },
            value: config.items,
            variablesSchema,
          })}
        />
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}

// NOTE: not the best algorithm, but better than nothing
function getAllowedNodeAsSubFlowRoot(flow: Flow, nodeKey: string) {
  const upstreamNodeKeys = getUpstreamNodeKeys(flow, nodeKey)
  const upstreamSubFlowNodeKeys = getUpstreamSubFlowNodeKeys(flow, nodeKey)

  const upstreamNodeKeysOfUpstreamSubFlows = upstreamSubFlowNodeKeys
    .map((key) => getUpstreamNodeKeys(flow, key))
    .flat()

  return (
    Object.keys(flow.nodes ?? {})
      .filter((key) => key !== nodeKey) // filter out current node
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      .filter((key) => !isTriggerNode(flow.nodes[key])) // filter out trigger nodes
      .filter((key) => !upstreamNodeKeys.includes(key)) // filter out upstream nodes
      .filter((key) => !upstreamSubFlowNodeKeys.includes(key)) // filter out upstream sub-flow nodes
      .filter((key) => !upstreamNodeKeysOfUpstreamSubFlows.includes(key))
  ) // filter out upstream nodes of upstream sub-flow nodes
}

/*
 * Returns all sub-flow node keys that are upstream of `nodeKey`
 */
function getUpstreamSubFlowNodeKeys(flow: Flow, nodeKey: string): string[] {
  const upstreamNodeKeys = getUpstreamNodeKeys(flow, nodeKey)

  const upstreamSubFlowNodeKeys = Object.keys(flow.nodes ?? {}).filter(
    (key) => {
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const node = flow.nodes[key]
      return upstreamNodeKeys.includes(node.config?.rootNodeKey)
    },
  )

  return [
    ...upstreamSubFlowNodeKeys,
    ...upstreamSubFlowNodeKeys
      .map((key) => {
        return getUpstreamSubFlowNodeKeys(flow, key)
      })
      .flat(),
  ]
}
