import clsx from 'utils/clsx'

import classes from './styles.module.css'

export function MenuButton({
  onClick,
  opened,
  className,
}: {
  onClick: () => void
  opened?: boolean
  className?: string
}) {
  return (
    <button
      onClick={onClick}
      className={clsx(
        classes.menuButton,
        opened ? classes.menuButton__opened : classes.menuButton__closed,
        className,
      )}
    >
      <span className={classes.menuButton_content}>
        <span></span>
        <span></span>
        <span></span>
      </span>
    </button>
  )
}
