import { makeDataField } from '@integration-app/react'

import AdminDataBuilderForm from 'components/AdminDataBuilderForm'
import { ConfigCard } from 'components/Card/ConfigCard'
import { ContentRows } from 'components/ContentRows'
import { Text } from 'ui-kit/text'

import { useGenericConfig } from './contexts/generic-config-context'
import { DataLinkTableAndDirectionConfig } from './DataLinkTableAndDirectionConfig'
import { getBrokenVariablesConfigurationErrors } from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/getBrokenVariablesConfigurationErrors'
import { NodeConfigurationErrorsGetter } from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/types'

export function DataLinkConfig() {
  const { config, patchConfig, variablesSchema } = useGenericConfig()

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>Configure Data Link</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content>
        <Text m={0}>Select Data Link Table and the link data to create</Text>

        <DataLinkTableAndDirectionConfig />

        <ContentRows>
          <AdminDataBuilderForm
            onChange={(externalRecordId) => patchConfig({ externalRecordId })}
            field={makeDataField({
              schema: {
                type: 'string',
                title: 'External Record ID',
              },
              value: config.externalRecordId,
              variablesSchema: variablesSchema,
            })}
          />

          <AdminDataBuilderForm
            onChange={(appRecordId) => patchConfig({ appRecordId })}
            field={makeDataField({
              schema: {
                type: 'string',
                title: 'App Record ID',
              },
              value: config.appRecordId,
              variablesSchema: variablesSchema,
            })}
          />
        </ContentRows>
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}

export const getDataLinkConfigErrors: NodeConfigurationErrorsGetter = ({
  runTimeVariablesSchema,
  config,
}) => {
  return [
    ...getBrokenVariablesConfigurationErrors(config.externalRecordId, [
      runTimeVariablesSchema,
    ]),
    ...getBrokenVariablesConfigurationErrors(config.appRecordId, [
      runTimeVariablesSchema,
    ]),
  ]
}
