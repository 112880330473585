import { useState } from 'react'
import { DataLinkTable } from '@integration-app/sdk'
import { uuid4 } from '@sentry/utils'

import { PopupCreateDataLinkForm } from 'routes/Workspaces/Workspace/Blueprints/DataLinkTables/components/PopupCreateDataLinkForm'

import { DataLinkTableConfig } from './DataLinkTableConfig'

const CreateNewDataLinkTableValue = uuid4()
const CreateNewDataLinkTableOption = {
  label: '+ Create New Data Link Table',
  value: CreateNewDataLinkTableValue,
}

export function DataLinkTableConfigWithCreatePopup({
  dataLinkTableKey,
  onChange,
}: {
  dataLinkTableKey: string
  onChange: (value: string) => void
}) {
  const [showDataLinkTableCreatePopup, setShowDataLinkTableCreatePopup] =
    useState(false)

  function handleOnChange(value) {
    if (value === CreateNewDataLinkTableValue) {
      setShowDataLinkTableCreatePopup(true)
    } else {
      setShowDataLinkTableCreatePopup(false)
      onChange(value)
    }
  }

  function handleDataLinkTableCreate(dataLinkTable: DataLinkTable) {
    onChange(dataLinkTable.key)
    setShowDataLinkTableCreatePopup(false)
  }

  return (
    <>
      <DataLinkTableConfig
        dataLinkTableKey={dataLinkTableKey}
        onChange={handleOnChange}
        additionalOptions={[CreateNewDataLinkTableOption]}
      />
      <PopupCreateDataLinkForm
        open={showDataLinkTableCreatePopup}
        onClose={() => setShowDataLinkTableCreatePopup(false)}
        onCreate={handleDataLinkTableCreate}
      />
    </>
  )
}
