import { useAddingNode } from 'components/FlowBuilder/Graph/elements/Edge/AddNodePlusButton'
import { Button } from 'ui-kit/button'
import clsx from 'utils/clsx'

import classes from '../styles.module.css'

export function AddNodeButton({
  parent,
  child,
  linkIndex,
  subgraphParent,
}: {
  parent: string
  child: string
  linkIndex: number
  subgraphParent?: string
}) {
  const { isAdding, isLinking, start } = useAddingNode({
    parent,
    child,
    linkIndex,
    subgraphParent,
  })

  const isActive = isAdding && !isLinking

  return (
    <Button
      className={clsx(classes.button, isActive && classes.buttonActive)}
      onClick={start}
      variant='subtle'
    >
      Add Node
    </Button>
  )
}
