import {
  DataBuilderForm,
  makeDataField,
  DataBuilderFilter,
} from '@integration-app/react'
import { schemaWithTitle, setSchemaAtLocator } from '@integration-app/sdk'
import useSWR from 'swr'

import { ConfigCard } from 'components/Card/ConfigCard'
import { OnOffSwitch } from 'components/Switch/OnOffSwitch'
import { useWorkspace } from 'components/Workspaces/workspace-context'
import { Text } from 'ui-kit/text'

import { useGenericConfig } from '../../../../../../../components/common-configs/contexts/generic-config-context'
import ConnectorEventSelect from '../../../../../../../components/ConnectorEvents/ConnectorEventSelect'

export function ConnectorEventTrigger() {
  const { engineAdminFetcher } = useWorkspace()
  const { config, patchConfig, variablesSchema, integrationId } =
    useGenericConfig()

  const eventKey = config?.eventKey

  const { data: eventSpec } = useSWR(
    integrationId && eventKey
      ? `/integrations/${integrationId}/events/${eventKey}`
      : null,
    engineAdminFetcher,
  )

  const filterEnabled = !!config?.filter

  // Add `input` to the schema available in filter
  let filterSchema = variablesSchema
    ? JSON.parse(JSON.stringify(variablesSchema))
    : undefined
  filterSchema = setSchemaAtLocator(filterSchema, 'input', eventSpec?.schema)

  return (
    <>
      <ConfigCard.Root>
        <ConfigCard.Header>
          <ConfigCard.Title>Connector Event</ConfigCard.Title>
        </ConfigCard.Header>
        <ConfigCard.Content>
          <ConnectorEventSelect
            value={eventKey}
            onChange={(eventKey) => patchConfig({ eventKey })}
          />
          {eventSpec?.parametersSchema && (
            <DataBuilderForm
              field={makeDataField({
                schema: schemaWithTitle(
                  eventSpec.parametersSchema,
                  'Parameters',
                ),
                value: config?.eventParameters,
              })}
              onChange={(eventParameters) => patchConfig({ eventParameters })}
            />
          )}
        </ConfigCard.Content>
      </ConfigCard.Root>
      <ConfigCard.NestedWrapper>
        <ConfigCard.Root>
          <ConfigCard.Header
            rightSlot={
              <OnOffSwitch
                tooltip={filterEnabled ? 'Disable filter' : 'Enable filter.'}
                checked={filterEnabled}
                onCheckedChange={() => {
                  void patchConfig({ filter: config?.filter ? undefined : {} })
                }}
              />
            }
          >
            <ConfigCard.Title>Filter</ConfigCard.Title>
          </ConfigCard.Header>
          <ConfigCard.Content>
            {filterEnabled ? (
              <DataBuilderFilter
                value={config?.filter}
                variablesSchema={filterSchema}
                onChange={(filter) => patchConfig({ filter })}
              />
            ) : (
              <Text color={'fg.muted'} m={0}>
                Enable to launch the flow only if event matches the filter.
              </Text>
            )}
          </ConfigCard.Content>
        </ConfigCard.Root>
      </ConfigCard.NestedWrapper>
    </>
  )
}
