import React from 'react'
import { DataSchema } from '@integration-app/sdk'

const FlowEditorDataContext = React.createContext<{
  getUserSchema: () => DataSchema
}>({
  getUserSchema: () => ({}),
})

export const FlowEditorDataProvider = FlowEditorDataContext.Provider

export function useFlowEditorData() {
  return React.useContext(FlowEditorDataContext)
}
