import { Navigate, Route, Routes, useParams } from 'react-router-dom'

import { HeadTitle } from 'components/AppLayout/HeadMetatags'
import { WorkspaceProvider } from 'components/Workspaces/workspace-context'
import CommonContextsWrapper from 'components/Wrappers/CommonContextsWrapper'
import useAuth from 'contexts/auth'
import RenderFullDoc from 'routes/Docs/components/RenderFullDoc'

export function AnonymousDocs() {
  return (
    <CommonContextsWrapper>
      <HeadTitle>Docs</HeadTitle>
      <Routes>
        <Route index element={<Navigate to='overview' replace />} />
        <Route path='*' element={<Docs />} />
      </Routes>
    </CommonContextsWrapper>
  )
}

function Docs() {
  const path = useParams()['*']

  const { self } = useAuth()

  if (!path) {
    return <Navigate to='overview' replace />
  }

  if (self?.workspace) {
    return (
      <WorkspaceProvider wsId={self.workspace?.id}>
        <RenderFullDoc path={path} />
      </WorkspaceProvider>
    )
  }

  return <RenderFullDoc path={path} />
}
