import { PropsWithChildren, useEffect, useState } from 'react'

import clsx from 'utils/clsx'

import { MenuButton } from './MenuButton'
import classes from './styles.module.css'

export function DocFullPageSidebar({ children }: PropsWithChildren) {
  const [opened, setOpened] = useState(false)
  function toggleClick() {
    setOpened(!opened)
  }

  useEffect(() => {
    if (opened) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }

    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [opened])

  return (
    <div
      className={clsx(
        classes.layout_aside,
        opened && classes.layout_aside__opened,
      )}
    >
      <MenuButton
        onClick={toggleClick}
        opened={opened}
        className={classes.aside_button}
      />
      <div className={classes.aside_content}>{children}</div>
    </div>
  )
}
