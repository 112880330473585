import { ConstantOptionFactory, makeDataField } from '@integration-app/react'
import { getLocatorsFromSchema } from '@integration-app/sdk'

export function makeFieldForSchema(schema: any, fields: any) {
  const locators = getLocatorsFromSchema(schema)

  const options = locators
    .filter((locator) => !fields?.includes(locator))
    .map((l) => ({
      label: l,
      value: l,
    }))

  return makeDataField({
    value: fields,
    optionFactories: [new ConstantOptionFactory(options)],
    schema: {
      type: 'array',
    },
  })
}
