import { useState, useEffect } from 'react'
import yaml from 'js-yaml'

import TextEditor from './TextEditor'

export default function YamlEditor({
  value,
  readOnly = false,
  scrollable = true,
  onChange,
}) {
  const [textValue, setTextValue] = useState<string | undefined>(undefined)
  const [initialized, setInitialized] = useState<boolean>(false)

  useEffect(() => {
    if (value !== undefined && !initialized) {
      setInitialized(true)
      setTextValue(value ? yaml.dump(value) : '')
    }
  }, [value])

  async function handleSave(value: any) {
    let parsedValue
    try {
      parsedValue = yaml.load(value)
    } catch {
      return
    }
    await onChange?.(parsedValue)
  }

  return (
    <TextEditor
      scrollable={scrollable}
      readOnly={readOnly}
      language={'yaml'}
      value={textValue}
      onChange={handleSave}
    />
  )
}
