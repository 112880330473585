import { forwardRef } from 'react'
import { Truncate, SvgIcon, SvgIconType } from '@integration-app/react'
import { FlowNode } from '@integration-app/sdk'

import { CONSOLE_NODE_SPECS } from 'routes/Workspaces/Workspace/Blueprints/Flows/Flow/Build/SidePanels/nodes'
import clsx from 'utils/clsx'

import classes from './PortalDropdown.module.css'

export const NodesDropdownMenu = forwardRef<
  HTMLDivElement,
  {
    nodes: Record<string, FlowNode>
    onHover: (nodeKey: string) => void
    onSelect: (nodeKey: string) => void
    selectedNodeKey: string
  }
>(({ nodes, onHover, onSelect, selectedNodeKey }, ref) => {
  const n = Object.keys(nodes).length

  return (
    <div className={classes.nodesMenu} ref={ref}>
      {Object.entries(nodes)
        .reverse()
        .map(([key, { name, type }], i) => (
          <div
            key={i}
            onMouseOver={() => onHover(key)}
            onMouseEnter={() => onHover(key)}
            onMouseLeave={() => onHover('')}
            onClick={() => onSelect(key)}
            className={clsx(
              classes.nodesMenuItem,
              selectedNodeKey === key && classes.selected,
              i === 0 && 'rounded-t',
              i === n - 1 && 'rounded-b',
            )}
          >
            <div
              className={clsx(
                selectedNodeKey === key && classes.selected,
                classes.nodesMenuItemNodeIcon,
              )}
            >
              {type ? (
                <img
                  src={`data:image/svg+xml;base64,${btoa(
                    CONSOLE_NODE_SPECS[type]?.icon,
                  )}`}
                  alt={name}
                />
              ) : (
                <SvgIcon type={SvgIconType.QuestionMark} />
              )}
            </div>

            <span>
              <Truncate mode={'end'} endLetters={10}>
                {name}
              </Truncate>
            </span>
          </div>
        ))}
    </div>
  )
})
