import { PropsWithChildren } from 'react'
import { Pane, VBox } from '@integration-app/ui/Layout'

import YAMLEditor from 'components/Editors/YamlEditor'
import { CodeModeSwitch, useCodeMode } from 'components/Switch/CodeModeSwitch'
import { css, cx } from 'styled-system/css'
import { Box, BoxProps } from 'styled-system/jsx'
import { Heading } from 'ui-kit/heading'

export function ElementPageWrapperWithCodeMode({
  title,
  children,
  onCodeChange,
  code,
}: PropsWithChildren<{
  title: string
  code: any
  onCodeChange(data: string): void
}>) {
  const { codeMode, toggleCodeMode } = useCodeMode()

  return (
    <Wrapper codeMode={codeMode} toggleCodeMode={toggleCodeMode} title={title}>
      {codeMode ? (
        <YAMLEditor value={code} onChange={onCodeChange} />
      ) : (
        children
      )}
    </Wrapper>
  )
}

function Wrapper({
  codeMode,
  toggleCodeMode,
  children,
  title,
}: PropsWithChildren<{
  title: string
  codeMode: boolean
  toggleCodeMode(): void
}>) {
  return (
    <VBox>
      <Pane
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 8,
          pt: 6,
          pb: 4,
        })}
      >
        <Heading as={'h1'} size={'3xl'} m={0}>
          {title}
        </Heading>

        <CodeModeSwitch
          codeMode={codeMode}
          onToggle={toggleCodeMode}
          className='mt-1'
        />
      </Pane>

      {codeMode ? (
        <Pane stretch>
          <div
            style={{
              // magic fix to avoid constant re-rendering of the editor
              // when scrollbar is always visible (like on windows)
              height: 'calc(100% - 1rem)',
            }}
          >
            {children}
          </div>
        </Pane>
      ) : (
        <Pane
          stretch
          className={css({
            px: 8,
            py: 4,
          })}
        >
          {children}
        </Pane>
      )}
    </VBox>
  )
}

const codeModeClassnames = css({
  height: 'calc(100% - 1rem)',
  paddingBlockStart: 14,
})

export function WrapperWithCodeModeSwitch({
  children,
  onCodeChange,
  code,
  ...props
}: BoxProps & {
  code: any
  onCodeChange(data: string): void
}) {
  const { codeMode, toggleCodeMode } = useCodeMode()

  // magic fix to avoid constant re-rendering of the editor
  // when scrollbar is always visible (like on windows)

  return (
    <Box
      position={'relative'}
      className={cx(codeMode && codeModeClassnames)}
      {...props}
    >
      <CodeModeSwitch
        codeMode={codeMode}
        onToggle={toggleCodeMode}
        className={css({
          position: 'absolute',
          right: 4,
          top: 4,
        })}
      />
      {codeMode ? (
        <YAMLEditor value={code} onChange={onCodeChange} />
      ) : (
        children
      )}
    </Box>
  )
}
