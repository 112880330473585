import { useRef } from 'react'
import { FlowNodeLink } from '@integration-app/sdk'
import { toSnakeCase } from 'js-convert-case'
import { useNavigate } from 'react-router-dom'

import { useFlowBuilder } from 'components/FlowBuilder/context/flow-builder-context'
import clsx from 'utils/clsx'

import { NODE_WIDTH } from '../common'
import classes from './Edge.module.css'

export function LinkNameOnEdgeCenter({
  x,
  y,
  link,
  nodeKey,
  interactive = true,
}: {
  x: number
  y: number
  link?: FlowNodeLink
  nodeKey: string
  interactive?: boolean
}) {
  const ref = useRef<HTMLSpanElement>(null)

  const spanHeight = 20

  const rename = useRenameLink(nodeKey, link?.name)

  if (!link?.name) {
    return null
  }

  const truncatedName =
    link.name.length > 16 ? `${link.name.slice(0, 16)}...` : link.name

  return (
    <foreignObject
      width={NODE_WIDTH / 2}
      height={spanHeight + 2}
      x={x - NODE_WIDTH / 4}
      y={y - spanHeight / 2}
      requiredExtensions='http://www.w3.org/1999/xhtml'
      style={{
        display: 'flex',
      }}
    >
      <span
        ref={ref}
        onClick={interactive ? rename : () => {}}
        className={clsx(
          classes.edgeLinkName,
          interactive && classes.edgeLinkNameHoverState,
        )}
      >
        {truncatedName}
      </span>
    </foreignObject>
  )
}

export function useRenameLink(nodeKey: string, linkName: string = 'default') {
  const navigate = useNavigate()
  const { baseRoute } = useFlowBuilder()

  return async () => {
    navigate(`${baseRoute}/nodes/${nodeKey}/links`)

    // wait 100ms for page to render the input
    await new Promise((resolve) => setTimeout(resolve, 100)).then(() => {
      const input = document?.querySelector(
        '#' + getInputIdForLinkName(linkName),
      ) as HTMLInputElement

      input?.select?.()
    })
  }
}

export function getInputIdForLinkName(linkName: string = 'default') {
  return toSnakeCase('link-' + linkName)
}
