import { Cell, Row, Title, FieldDescription } from '@integration-app/react'

import { Select, SelectItemType } from 'components/Select'

export type SimpleSelectOptionType = SelectItemType

export default function SimpleSelect({
  name,
  description,
  value,
  options,
  onChange,
  required,
  disabled,
}: {
  name: string
  description?: string
  value: string
  options: SelectItemType[]
  onChange: (value: string) => void
  required?: boolean
  disabled?: boolean
}) {
  const selectedValue = options.find((option) => option.value === value)
  function handleValueChange(value: SelectItemType) {
    !!value && onChange(value.value)
  }
  return (
    <Row>
      <Cell.Name>
        <Title
          required={required && !value}
          rightSlot={<FieldDescription text={description} />}
        >
          {name}
        </Title>
      </Cell.Name>
      <Cell.Combobox grow>
        <Select
          value={selectedValue}
          items={options}
          onValueChange={handleValueChange}
          disabled={disabled}
          variant='ghost'
          placeholder='Select...'
          my={1}
        />
      </Cell.Combobox>
    </Row>
  )
}
