import { SvgIcon, SvgIconType } from '@integration-app/react'
import { useRouter } from 'next/router'
import { NavLink } from 'react-router-dom'

import { getArticleBreadcrumbs } from 'routes/Docs/helpers/articleList'

import { DocBreadcrumbs } from './DocsBreadcrumbs'

export default function DocBreadcrumbsFromPath({ path }: { path: string }) {
  if (path === 'overview') {
    return null
  }

  const breadcrumbs = getArticleBreadcrumbs(path)

  return <DocBreadcrumbs breadcrumbs={breadcrumbs} />
}

export function DocsHeaderBreadcrumbs({ path }: { path: string }) {
  const breadcrumbs = [
    {
      title: <IntegrationAllBreadcrumb />,
    },
    {
      title: 'Docs',
      path: '/',
    },
    ...getArticleBreadcrumbs(path),
  ]

  return <DocBreadcrumbs breadcrumbs={breadcrumbs} />
}

function IntegrationAllBreadcrumb() {
  const { route } = useRouter()

  const isDocInWorkspace = route.startsWith('/w/[workspaceId]')

  const Title = () => (
    <>
      <SvgIcon className={'w-8 h-5 mr-2'} type={SvgIconType.AppIcon} />{' '}
      Integration.app
    </>
  )

  const classes = 'py-1 px-1.5 -mx-1.5 inline-flex items-center font-medium'

  if (!isDocInWorkspace)
    return (
      <a href={'https://integration.app'} className={classes}>
        <Title />
      </a>
    )

  return (
    <NavLink to={'/'} className={classes}>
      <Title />
    </NavLink>
  )
}
