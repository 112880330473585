import { useIntegrationApp } from '@integration-app/react'
import {
  DataCollectionSpec,
  DataSource,
  Flow,
  FlowDependencyType,
  parseDataLocationPath,
} from '@integration-app/sdk'
import useSWR from 'swr'

export function useDataCollectionsInFlowNodes(flow: Flow) {
  const client = useIntegrationApp()

  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2322): Type '(string | undefined)[]' is not assignable to... Remove this comment to see the full error message
  const defaultPathsFromDependencies: string[] = flow.integrationId
    ? (flow.dependencies ?? [])
        .filter((dep) => dep.type === FlowDependencyType.DataSource)
        .map((dep) => (dep.element as DataSource).defaultPath)
        .filter(Boolean)
    : []

  const defaultPathsFromNodes: string[] = Object.values(
    flow.nodes ?? {},
  ).reduce((defaultPaths, node) => {
    const dataSourceConfig = node.config?.dataSource ?? {}

    if (!dataSourceConfig.key && dataSourceConfig.defaultPath) {
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      defaultPaths.push(dataSourceConfig.defaultPath)
    }
    return defaultPaths
  }, [])

  const defaultPaths = [
    ...defaultPathsFromDependencies,
    ...defaultPathsFromNodes,
  ]

  const { data: dataCollectionSpecs = [] } = useSWR<
    { spec: DataCollectionSpec; path: string }[]
  >(
    defaultPaths.length > 0 && flow.integrationId
      ? `defaultPaths:${flow.id}`
      : null,
    async () => {
      const promises = defaultPaths.map(async (path) => ({
        path,
        spec: await client
          // FIXME: strictNullCheck temporary fix
          // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
          .integration(flow.integrationId)
          .getDataCollection(parseDataLocationPath(path)?.key),
      }))

      return await Promise.all(promises)
    },
  )

  return dataCollectionSpecs
}
