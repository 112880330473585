import { SearchBox } from 'react-instantsearch'
import { SearchBoxProps } from 'react-instantsearch/dist/es/widgets/SearchBox'

import { sva } from 'styled-system/css'

const docSearchInputRecipe = sva({
  slots: ['root', 'form', 'input', 'submit', 'reset', 'loadingIndicator'],
  base: {
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    form: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    input: {
      flexGrow: 1,
      textStyle: 'lg',
      fontWeight: 'medium',
      backgroundColor: 'transparent',
      paddingInline: 5,
      paddingBlock: 2.5,
      _focusWithin: {
        outline: 'none',
      },
      '&::-webkit-search-decoration, &::-webkit-search-cancel-button,&::-webkit-search-results-button,&::-webkit-search-results-decoration':
        {
          display: 'none',
        },
    },
    submit: {
      display: 'none',
    },
    reset: {
      display: 'none',
    },
    loadingIndicator: {
      marginInlineEnd: 12,
    },
  },
})
const docSearchInputClassnames = docSearchInputRecipe({})

export function StylesSearchBox(props: Omit<SearchBoxProps, 'classNames'>) {
  return <SearchBox classNames={docSearchInputClassnames} {...props} />
}
