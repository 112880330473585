import { MDXProvider } from '@mdx-js/react'

import { useWorkspace } from 'components/Workspaces/workspace-context'
import { DocArticleStyleWrapper } from 'routes/components/MarkdownViewer'
import { mdxProviderComponents } from 'routes/Docs/helpers/markdown-components'
import { DocArticleType } from 'routes/Docs/types'

export function ArticleMdx({
  article,
  parameters,
}: {
  article?: DocArticleType
  parameters?: any
}) {
  const { workspace, testCustomerAccessToken } = useWorkspace()

  const Page = article?.Component

  const docComponent = Page ? (
    <Page
      ws={workspace}
      parameters={parameters}
      token={testCustomerAccessToken}
    />
  ) : (
    <DocNotFound />
  )

  return (
    <DocArticleStyleWrapper>
      <MDXProvider components={mdxProviderComponents}>
        {docComponent}
      </MDXProvider>
    </DocArticleStyleWrapper>
  )
}

function DocNotFound() {
  return <p>Documentation article not found</p>
}
