import { CodeBlockElement } from 'components/CodeBlock/elements'
import { CodeHighlighterLanguages } from 'components/CodeHighlighter'

export default function PreTagHighlighted({ children }) {
  const { className, children: code } = children?.props
  const language = /language-(\w+)/.exec(className || '')?.[1]

  if (!language || typeof code !== 'string') {
    return <pre>{children}</pre>
  }
  return (
    <CodeBlockElement.Root>
      <CodeBlockElement.Code
        code={code}
        language={language as CodeHighlighterLanguages}
      />
    </CodeBlockElement.Root>
  )
}
