import { ListFilterConfig } from 'routes/Workspaces/Workspace/components/common-configs/ListFilterConfig'

import { useGenericConfig } from './contexts/generic-config-context'
import { CustomizeImplementation } from './CustomizeImplementation'
import { DataCollectionMethodParametersConfig } from './DataCollectionMethodParametersConfig'
import { DataSourceUnitConfig } from './DataSourceUnitConfig'
import { ImportFieldMappingUnitConfig } from './ImportFieldMappingUnitConfig'
import { PaginationConfig } from './PaginationConfig'

export function ListDataRecordsUnitConfig({
  showPagination = true,
}: {
  showPagination?: boolean
}) {
  const { dataCollectionSpec } = useGenericConfig()

  const apiRequests = dataCollectionSpec?.list?.apiRequests

  return (
    <>
      <DataSourceUnitConfig />

      <DataCollectionMethodParametersConfig methodKey='list' />

      <ListFilterConfig />

      {showPagination && <PaginationConfig />}

      <CustomizeImplementation apiRequests={apiRequests} />

      <ImportFieldMappingUnitConfig />
    </>
  )
}
