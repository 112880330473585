import { PropsWithChildren } from 'react'

import clsx from 'utils/clsx'

import classes from './MarkdownViewer.module.css'

export function DocArticleStyleWrapper({
  children,
  className,
}: PropsWithChildren<{
  className?: string
}>) {
  return <div className={clsx(classes.content, className)}>{children}</div>
}
