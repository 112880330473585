import React, { ReactNode, HTMLAttributes } from 'react'

import { GRAPH_NODE_STYLES } from '../common'

export function NodeBodyContainer({
  children,
  ...props
}: {
  children: ReactNode
} & HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      style={{
        width: GRAPH_NODE_STYLES.width,
        height: GRAPH_NODE_STYLES.height,
      }}
      {...props}
    >
      {children}
    </div>
  )
}
