import {
  useActions,
  DataBuilderForm,
  makeDataField,
  Row,
  Cell,
} from '@integration-app/react'

import { ConfigCard } from 'components/Card/ConfigCard'
import { GoToElementLink } from 'components/GoToElemenLink'
import { Select, SelectItemType } from 'components/Select'
import { Text } from 'ui-kit/text'

import { useGenericConfig } from '../../../../../../../components/common-configs/contexts/generic-config-context'
import { routeTo } from '../../../../../../../routes-constants'

export function RunAction() {
  const { config, patchConfig, integrationId, variablesSchema } =
    useGenericConfig()

  const { actions } = useActions({
    integrationId: integrationId ?? 'null',
  })

  const options: SelectItemType[] = actions.map((action) => ({
    label: action.name,
    value: action.key,
  }))

  const selectedOption = options.find(
    ({ value }) => value === config.action?.key,
  )
  const selectedAction = actions.find(({ key }) => key === config.action?.key)

  function handleValueChange({ value }: SelectItemType) {
    return patchConfig({
      ...config,
      action: { key: value },
    })
  }

  return (
    <>
      <ConfigCard.Root>
        <ConfigCard.Header>
          <ConfigCard.Title>Action</ConfigCard.Title>
        </ConfigCard.Header>
        <ConfigCard.Content display={'flex'} flexDirection={'column'} gap={2}>
          <Text m={0} marginBlockEnd={1}>
            Select which action to run.
          </Text>
          <Row>
            <Cell.Name shrink>Action</Cell.Name>
            <Cell.Combobox grow>
              <Select
                items={options}
                value={selectedOption}
                onValueChange={handleValueChange}
                variant='ghost'
                my={1}
              />
            </Cell.Combobox>
          </Row>

          {selectedAction && (
            <GoToElementLink to={routeTo.action(selectedAction)} />
          )}
        </ConfigCard.Content>
      </ConfigCard.Root>
      {selectedAction && selectedAction.inputSchema && (
        <ConfigCard.NestedWrapper>
          <ConfigCard.Root>
            <ConfigCard.Header>
              <ConfigCard.Title>Input</ConfigCard.Title>
            </ConfigCard.Header>
            <ConfigCard.Content>
              <DataBuilderForm
                field={makeDataField({
                  schema: selectedAction.inputSchema,
                  value: config.input,
                  variablesSchema,
                })}
                onChange={(input) => patchConfig({ input })}
              />
            </ConfigCard.Content>
          </ConfigCard.Root>
        </ConfigCard.NestedWrapper>
      )}
    </>
  )
}
