import { DataBuilderForm, makeDataField } from '@integration-app/react'

import { ConfigCard } from 'components/Card/ConfigCard'
import { useFlowNode } from 'components/FlowBuilder/context/flow-node-context'
import { Text } from 'ui-kit/text'

import { ForEachV2 } from './ForEachV2'
import { useGenericConfig } from '../../../../../../../components/common-configs/contexts/generic-config-context'
import { getBrokenVariablesConfigurationErrors } from '../configuration-errors/getBrokenVariablesConfigurationErrors'
import { NodeConfigurationErrorsGetter } from '../configuration-errors/types'

export function ForEach() {
  const { node } = useFlowNode()
  const { patchConfig, config, variablesSchema } = useGenericConfig()

  if (node.version == 2) {
    return <ForEachV2 />
  }

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>For Each</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content>
        <Text>
          Please select or construct a list to iterate over. Following nodes
          will be executed for each item in this list.
        </Text>

        <DataBuilderForm
          onChange={(list) => patchConfig({ list })}
          field={makeDataField({
            schema: { type: 'array', title: 'List' },
            value: config.list,
            variablesSchema,
          })}
        />
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}

export const getForEachConfigErrors: NodeConfigurationErrorsGetter = ({
  config,
  runTimeVariablesSchema,
}) => {
  const errors = []

  errors.push(
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2345): Argument of type 'NodeConfigurationErrorData' is n... Remove this comment to see the full error message
    ...getBrokenVariablesConfigurationErrors(config.list, [
      runTimeVariablesSchema,
    ]),
  )

  return errors
}
