import { getArticle, getArticleDocMenu } from 'routes/Docs/helpers/articleList'
import {
  DocMenuLinkType,
  DocMenuType,
  isDocMenuSection,
  isMenuItemHeaderType,
  MenuItemHeaderType,
  MenuItemLinkType,
  MenuItemType,
} from 'routes/Docs/types'

export function getDocMenu(pathname: string): MenuItemType[] {
  const rawArticleDocMenu = getArticleDocMenu()

  let docsMenuLinks: MenuItemType[] = mapOverMenuTree(
    rawArticleDocMenu,
    convertDocItemToMenuItem,
  )

  docsMenuLinks = mapOverMenuTree(docsMenuLinks, (item) =>
    setMenuItemAttributes(item, pathname),
  )

  return docsMenuLinks
}

function mapOverMenuTree<
  T extends Record<string, unknown>,
  K extends Record<string, unknown>,
>(links: T[], fn: (link: T) => K) {
  return links.map((link) => {
    const links = (link as Record<string, T[]>)?.links

    const result = {
      ...link,
      links: !!links ? mapOverMenuTree(links, fn) : undefined,
    }
    return fn(result)
  })
}

function convertDocItemToMenuItem(item: DocMenuType): MenuItemType {
  if (isDocMenuSection(item)) {
    return { ...item } as MenuItemHeaderType
  }

  const linkItem = item as DocMenuLinkType
  const article = getArticle(linkItem.path)

  if (!article) {
    throw new Error(`Article with path "${linkItem.path}" not found`)
  }

  return {
    ...linkItem,
    to: linkItem.path,
    title: linkItem.title ?? article.title,
  } as MenuItemLinkType
}

function setMenuItemAttributes(
  link: MenuItemType,
  pathname: string,
): MenuItemType {
  const hasChildren = (link?.links?.length ?? 0) > 0
  const hasActiveChildren = itemHasActiveChildByPathname(link, pathname)
  const isActive = isLinkInPathname(link, pathname) && !hasActiveChildren
  const isExpand = (isActive || hasActiveChildren) && hasChildren

  return {
    ...link,
    isActive,
    isExpand,
    hasChildren,
  }
}

function itemHasActiveChildByPathname(
  item: MenuItemType,
  pathname: string,
): boolean {
  return (item?.links ?? []).some(
    (child) =>
      isLinkInPathname(child, pathname) ||
      itemHasActiveChildByPathname(child, pathname),
  )
}

function isLinkInPathname(link: MenuItemType, pathname: string) {
  if (isMenuItemHeaderType(link)) return false

  return pathname === link.to
}
