import { SvgIcon, SvgIconType } from '@integration-app/react'
import { AppDataSchema } from '@integration-app/sdk'

import { ConfigCard } from 'components/Card/ConfigCard'
import { GoToElementLink } from 'components/GoToElemenLink'

import { routeTo } from '../../routes-constants'

export default function RelatedAppDataSchemaSection({
  appDataSchema,
}: {
  appDataSchema?: AppDataSchema
}) {
  if (!appDataSchema) {
    return null
  }
  return (
    <ConfigCard.Root>
      <ConfigCard.Header
        leftSlot={<SvgIcon type={SvgIconType.DataSchemaEntity} />}
      >
        <ConfigCard.Title>
          App Data Schema:
          <GoToElementLink to={routeTo.appDataSchema(appDataSchema)}>
            {appDataSchema.name ?? appDataSchema.key}
          </GoToElementLink>
        </ConfigCard.Title>
      </ConfigCard.Header>
    </ConfigCard.Root>
  )
}
