import { useFlowBuilder } from 'components/FlowBuilder/context/flow-builder-context'
import { Button } from 'ui-kit/button'
import clsx from 'utils/clsx'

import classes from '../styles.module.css'

export function AddLinkButton({
  linkIndex,
  parent,
}: {
  parent: string
  linkIndex: number
}) {
  const { linking, startLinking } = useFlowBuilder()
  const isActive =
    linking && linking.parent === parent && linking.index === linkIndex

  function handleStartLinking() {
    startLinking(parent, linkIndex)
  }

  return (
    <Button
      className={clsx(classes.button, isActive && classes.buttonActive)}
      onClick={handleStartLinking}
      variant='subtle'
    >
      Link Node
    </Button>
  )
}
