import { NavLink } from 'react-router-dom'

import { ConfigCard } from 'components/Card/ConfigCard'
import { Text } from 'ui-kit/text'

import { HttpRequestConfig } from '../../../../../../../components/common-configs/HttpRequestConfig'
import { HttpResponseConfig } from '../../../../../../../components/common-configs/HttpResponseConfig'
import { YOUR_APP_API_ROUTE } from '../../../../../../../routes-constants'

export function ApiRequestToYourApp() {
  return (
    <>
      <ConfigCard.Root>
        <ConfigCard.Header>
          <ConfigCard.Title>Request to your App</ConfigCard.Title>
        </ConfigCard.Header>
        <ConfigCard.Content>
          <Text m={0}>
            You can configure authentication and default parameters of these
            requests here:{' '}
            <NavLink className='link' to={YOUR_APP_API_ROUTE}>
              My App API configuration
            </NavLink>
            .
          </Text>
        </ConfigCard.Content>
      </ConfigCard.Root>
      <ConfigCard.NestedWrapper>
        <HttpRequestConfig />

        <HttpResponseConfig />
      </ConfigCard.NestedWrapper>
    </>
  )
}
