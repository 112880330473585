import { useGenericConfig } from './contexts/generic-config-context'
import { CustomizeImplementation } from './CustomizeImplementation'
import { DataCollectionMethodParametersConfig } from './DataCollectionMethodParametersConfig'
import { DataSourceUnitConfig } from './DataSourceUnitConfig'
import { ExportFieldMappingUnitConfig } from './ExportFieldMappingUnitConfig'

export function CreateDataRecordUnitConfig() {
  const { dataCollectionSpec } = useGenericConfig()

  const apiRequests = dataCollectionSpec?.create?.apiRequests

  return (
    <>
      <DataSourceUnitConfig />

      <DataCollectionMethodParametersConfig methodKey='create' />

      <ExportFieldMappingUnitConfig />

      <CustomizeImplementation apiRequests={apiRequests} />
    </>
  )
}
