import { ComponentProps } from 'react'

import { Grid } from 'styled-system/jsx'
import { Heading } from 'ui-kit/heading'
import { Text } from 'ui-kit/text'

import { ButtonWithSlots } from '../../Button/ButtonWithSlots'

function ItemButtonRoot({
  children,
  leftSlot,
  ...props
}: ComponentProps<typeof ButtonWithSlots>) {
  return (
    <ButtonWithSlots
      variant={'subtle'}
      lineHeight={'tight'}
      height={'auto'}
      padding={3}
      leftSlot={leftSlot}
      {...props}
    >
      <Grid
        gridTemplateColumns={'1fr'}
        gap={0}
        alignItems={'start'}
        flexGrow={0}
        flexShrink={1}
      >
        {children}
      </Grid>
    </ButtonWithSlots>
  )
}

function Header({ children, ...props }: ComponentProps<typeof Heading>) {
  return (
    <Heading
      as={'h4'}
      size={'md'}
      fontWeight={'normal'}
      margin={0}
      lineHeight={'tight'}
      truncate
      {...props}
    >
      {children}
    </Heading>
  )
}

function Description({ children, ...props }: ComponentProps<typeof Text>) {
  return (
    <Text
      color={'fg.muted'}
      margin={0}
      lineHeight={'tight'}
      truncate
      {...props}
    >
      {children}
    </Text>
  )
}

const ItemButton = {
  Root: ItemButtonRoot,
  Header: Header,
  Description: Description,
}

export { ItemButton }
