import { useState } from 'react'
import { FlowNode } from '@integration-app/sdk'
import { NodeProps } from 'reactflow'

import { useFlowBuilder } from 'components/FlowBuilder/context/flow-builder-context'
import { NODE_HEIGHT } from 'components/FlowBuilder/Graph/elements/common'
import { RegularNodeData } from 'components/FlowBuilder/Graph/nodes/RegularNode/RegularNode'
import { TriggerNodeData } from 'components/FlowBuilder/Graph/nodes/TriggerNode/TriggerNode'
import clsx from 'utils/clsx'

import { GraphNodeType } from '../index'
import { NodesDropdownMenu } from './PortalDropdown/NodesDropdownMenu'

function PortalDropdown({
  portalNodes,
}: {
  flowNode: FlowNode
  portalNodes: Record<string, FlowNode>
}) {
  const { selectNode, selectedNodeKey, hoverNode, hoveringNodeKey } =
    useFlowBuilder()
  const [isOpen, setIsOpen] = useState(false)

  const hasPortalNodes = Object.keys(portalNodes ?? {}).length > 0
  if (!hasPortalNodes) {
    return null
  }

  const nodesKeys = Object.keys(portalNodes).slice(0, 3)

  return (
    <div
      className='absolute'
      style={{
        bottom: NODE_HEIGHT - 8,
        left: 8,
      }}
    >
      {isOpen && (
        <div onMouseLeave={() => setIsOpen(false)}>
          <NodesDropdownMenu
            nodes={portalNodes}
            onHover={hoverNode}
            selectedNodeKey={selectedNodeKey}
            onSelect={selectNode}
          />
        </div>
      )}

      {!isOpen && (
        <div className='relative' onMouseEnter={() => setIsOpen(true)}>
          {nodesKeys.map((key, i) => (
            <div
              key={key}
              className={'absolute'}
              style={{
                bottom: i * 10,
                marginLeft: i * 10,
              }}
            >
              <div
                className={clsx(
                  'flex gap-1 p-1 bg-white border border-neutral03 rounded w-16 cursor-pointer',
                  hoveringNodeKey === key && 'border-neutral05',
                  selectedNodeKey === key && 'bg-primaryColor',
                )}
              >
                <div
                  className={clsx(
                    'bg-primaryColor w-3 h-3 rounded',
                    selectedNodeKey === key && 'bg-secondaryColor',
                  )}
                />

                <div className={'flex flex-col gap-1'}>
                  <div className='w-8 h-1 bg-neutral01 rounded' />
                  <div className='w-6 h-1 bg-neutral01 rounded' />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className='ml-4 border-l border-neutral03 h-6' />
    </div>
  )
}

export function PortalNodesDropdown(
  props: NodeProps<RegularNodeData | TriggerNodeData>,
) {
  if (props.type !== GraphNodeType.Node) {
    return null
  }

  const { portalNodes, flowNode } = props.data as RegularNodeData
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2322): Type 'Record<string, FlowInstanceNode> | undefined... Remove this comment to see the full error message
  return <PortalDropdown portalNodes={portalNodes} flowNode={flowNode} />
}
