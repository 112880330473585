import {
  Cell,
  Row,
  ComboBox,
  ComboBoxOption,
  useAppDataSchemas,
  ValueType,
} from '@integration-app/react'

import { routeTo } from 'routes/Workspaces/Workspace/routes-constants'

import { GoToElementLink } from '../GoToElemenLink'

export default function AppDataSchemaSelect({
  value,
  onChange,
  disabled = false,
}: {
  value?: string
  onChange: (value: string | undefined) => void
  disabled?: boolean
}) {
  const { appDataSchemas } = useAppDataSchemas()

  const options: ComboBoxOption[] = appDataSchemas.map(({ name, key }) => ({
    label: name,
    value: key,
  }))

  async function handleChange(value: string | undefined) {
    onChange(value)
  }

  const selected = appDataSchemas.find(({ key }) => key === value)

  return (
    <div className='flex items-center gap-4'>
      <Row>
        <Cell.Name shrink>App Data Schema</Cell.Name>
        <Cell.Combobox grow>
          <ComboBox
            value={value}
            valueSpec={{ type: ValueType.STRING, allowCustom: false }}
            options={options}
            onChange={handleChange}
            disabled={disabled}
            placeholder='Select Data Schema'
          />
        </Cell.Combobox>
      </Row>

      {selected && (
        <GoToElementLink to={routeTo.appDataSchema(selected.id)}>
          View/Edit Schema
        </GoToElementLink>
      )}
    </div>
  )
}
