import { IntegrationElementLevel } from '@integration-app/sdk'

import { ConnectionLevelDataSourceUnitConfig } from './ConnectionLevelDataSourceUnitConfig'
import { IntegrationLevelDataSourceUnitConfig } from './IntegrationLevelDataSourceUnitConfig'
import { UniversalDataSourceUnitConfig } from './UniversalDataSourceUnitConfig'
import { useGenericConfig } from '../contexts/generic-config-context'

export function DataSourceUnitConfig({
  configureEvents = false,
}: {
  configureEvents?: boolean
}) {
  const { level } = useGenericConfig()

  switch (level) {
    case IntegrationElementLevel.UNIVERSAL:
      return <UniversalDataSourceUnitConfig configureEvents={configureEvents} />
    case IntegrationElementLevel.CONNECTOR:
      return (
        <IntegrationLevelDataSourceUnitConfig
          configureEvents={configureEvents}
        />
      )
    case IntegrationElementLevel.CONNECTION:
      return (
        <ConnectionLevelDataSourceUnitConfig
          configureEvents={configureEvents}
        />
      )
    default:
      return null
  }
}
