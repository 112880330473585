import { PropsWithChildren } from 'react'
import { SvgIcon, SvgIconType } from '@integration-app/react'
import { FlowNode } from '@integration-app/sdk'
import useEventStopPropagation from '@integration-app/ui/hooks/useEventStopPropagation'

import { CONSOLE_NODE_SPECS } from 'routes/Workspaces/Workspace/Blueprints/Flows/Flow/Build/SidePanels/nodes'
import clsx from 'utils/clsx'

import classes from './NodeBody.module.css'
import {
  NodeStackItem,
  PortalNodeBodyStackItem,
} from './NodeStackItems/NodeStackItems'
import { INodeStackItem } from './NodeStackItems/stack-item'
import { useNodeMouseEvents } from './useNodesHoveringState'
import { GraphNodeType } from '../index'

export enum NodeBodyType {
  Regular = 'regular',
  Trigger = 'trigger',
  Portal = 'portal',
}

export function NodeBody({
  flowNodeKey,
  flowNode,
  type = NodeBodyType.Regular,
  customIcon,
  hasError = false,
  isSelected = false,
  isHovered = false,
  onClick,
  children,
  onNodeHover,
  stackItems = [],
}: PropsWithChildren<{
  type?: NodeBodyType
  flowNodeKey: string
  flowNode: FlowNode
  onClick(): void
  onNodeHover?(nodeKey: string): void
  hasError?: boolean
  isSelected?: boolean
  isHovered?: boolean
  disableHover?: boolean
  stackItems?: INodeStackItem[]
  /*
   * SVG icon string. If not specified, the icon will be taken from the flow node type.
   */
  customIcon?: string
}>) {
  const { name } = flowNode
  const icon =
    customIcon ??
    (flowNode.type ? CONSOLE_NODE_SPECS[flowNode.type]?.icon : undefined)

  const isPortalNode = type === NodeBodyType.Portal

  const { handleMouseEnter, handleMouseLeave } = useNodeMouseEvents(
    flowNodeKey,
    onNodeHover,
  )

  const stackItemsLength = stackItems?.length ?? 0

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {isPortalNode && (
        <div
          onClick={onClick}
          className={clsx(
            classes.nodeStackItemsTop,
            (isSelected || isHovered) && classes.nodeStackItemsTopHovered,
          )}
        >
          <PortalNodeBodyStackItem />
        </div>
      )}

      <div
        className={clsx(
          classes.node,
          isSelected && classes.nodeActive,
          isHovered && classes.nodeHovered,
          type === NodeBodyType.Trigger && classes.triggerNode,
          type === NodeBodyType.Portal && classes.portalNode,
          // disableHover && classes.disableHover,
          !isPortalNode && 'rounded-t',
          !stackItemsLength && 'rounded-b',
        )}
        onMouseDownCapture={useEventStopPropagation(onClick)}
        role='button'
        aria-pressed={isSelected}
      >
        <NodeBodyIcon icon={icon} />

        <div className={classes.nodeTitle}>{name ?? 'node'}</div>

        {hasError && <div className={classes.nodeErrorDot} />}

        {children && children}
      </div>

      {stackItemsLength > 0 && (
        <div
          className={clsx(
            (isSelected || isHovered) && classes.nodeStackItemsTopHovered,
          )}
        >
          {stackItems.map((item) => (
            <NodeStackItem
              key={item.text}
              onClick={onClick}
              selected={isSelected}
              isBottom={true}
              {...item}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export function NodeBodyIcon({ icon, className = '' }) {
  return (
    <div className={clsx(classes.nodeIcon, className)}>
      {icon ? (
        <img src={`data:image/svg+xml;base64,${btoa(icon)}`} alt={'icon'} />
      ) : (
        <SvgIcon type={SvgIconType.QuestionMark} />
      )}
    </div>
  )
}

export function NodeBodyIconCombobox({ icon, className = '' }) {
  return (
    <div className={clsx(classes.nodeIconCombobox, className)}>
      <img src={`data:image/svg+xml;base64,${btoa(icon)}`} alt={'icon'} />
    </div>
  )
}

export function getNodeBodyTypeFromGraphElementType(
  gt: GraphNodeType | string,
): NodeBodyType {
  switch (gt) {
    case GraphNodeType.Node:
      return NodeBodyType.Regular
    case GraphNodeType.Trigger:
      return NodeBodyType.Trigger
    case GraphNodeType.Portal:
      return NodeBodyType.Portal
    default:
      throw new Error(`Unknown graph element type: ${gt}`)
  }
}
