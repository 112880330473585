import { NodeProps } from 'reactflow'

import { TargetHandle } from 'components/FlowBuilder/Graph/elements/Handle/Handle'
import {
  NodeBody,
  NodeBodyType,
} from 'components/FlowBuilder/Graph/elements/NodeBody/NodeBody'
import { NodeBodyContainer } from 'components/FlowBuilder/Graph/elements/NodeBody/NodeBodyContainer'
import {
  PORTAL_NODE_ICON,
  PortalNodeData,
} from 'components/FlowBuilder/Graph/nodes/PortalNode/PortalNode'

// TODO: there are two PortalNode components
export function PortalNode(props: NodeProps<PortalNodeData>) {
  const { portalFlowNode, portalFlowNodeKey } = props.data

  return (
    <NodeBodyContainer>
      <TargetHandle />

      <NodeBody
        flowNodeKey={portalFlowNodeKey}
        flowNode={portalFlowNode}
        type={NodeBodyType.Portal}
        onClick={() => {}}
        customIcon={PORTAL_NODE_ICON}
        isHovered={false}
        onNodeHover={() => {}}
      />
    </NodeBodyContainer>
  )
}
