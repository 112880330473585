import { SourceHandle } from './Handle/Handle'
import { NodeBodyContainer } from './NodeBody/NodeBodyContainer'

export function RootNode() {
  return (
    <NodeBodyContainer>
      <div />
      <SourceHandle />
    </NodeBodyContainer>
  )
}
