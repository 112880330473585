import { DataSourceUnitConfig } from '../../../../../../../components/common-configs/DataSourceUnitConfig'
import { ImportFieldMappingUnitConfig } from '../../../../../../../components/common-configs/ImportFieldMappingUnitConfig'
import { LookupQueryConfig } from '../../../../../../../components/common-configs/LookupQueryConfig'

export function FindDataRecords() {
  return (
    <>
      <DataSourceUnitConfig />

      <LookupQueryConfig />

      <ImportFieldMappingUnitConfig />
    </>
  )
}
