import { PropsWithChildren } from 'react'
import Markdown, { MarkdownToJSX } from 'markdown-to-jsx'
import { useNavigate } from 'react-router-dom'

import {
  ArticleTocHeader,
  ArticleTocStylesForMarkdownToc,
} from 'routes/Docs/components/ArticleToc'
import { getSlug } from 'utils/slug'

import {
  ArticleActiveHeaderIdProvider,
  useArticleActiveHeaderId,
} from '../ArticleActiveHeader'

export function MarkdownViewer({
  children,
  className,
  options,
}: {
  children: string
  className?: string
  options?: MarkdownToJSX.Options
}) {
  const optionsWithSlugify = {
    slugify: getSlug,
    ...options,
  }
  return (
    <Markdown options={optionsWithSlugify} className={className}>
      {children}
    </Markdown>
  )
}

const generateListComponent =
  (tagName) =>
  ({ children, id }) => {
    return (
      <MarkdownTocItem id={id} tagName={tagName}>
        {children}
      </MarkdownTocItem>
    )
  }

const nullComponent = () => null

const allowedElementsInToc = ['h2', 'h3'].reduce((acc, x) => {
  return { ...acc, [x]: generateListComponent(x) }
}, {})

const tocOverrides = new Proxy(allowedElementsInToc, {
  get(target, name) {
    return target[name] || nullComponent
  },
})

export function MarkdownToc({
  children,
  options,
  articleContainerId,
}: {
  children: string
  options?: MarkdownToJSX.Options
  articleContainerId: string
}) {
  const headersOnlyText = normalizeHeaders(getHeadersFromMarkdown(children))

  if (!headersOnlyText) {
    return null
  }

  const optionsWithSlugify: MarkdownToJSX.Options = {
    slugify: getSlug,
    overrides: tocOverrides,
    ...options,
  }

  return (
    <ArticleActiveHeaderIdProvider articleContainerId={articleContainerId}>
      <ArticleTocStylesForMarkdownToc>
        <ul>
          <li>
            <ArticleTocHeader />
          </li>
          <Markdown options={optionsWithSlugify}>{headersOnlyText}</Markdown>
        </ul>
      </ArticleTocStylesForMarkdownToc>
    </ArticleActiveHeaderIdProvider>
  )
}

function MarkdownTocItem({
  children,
  tagName,
  id,
}: PropsWithChildren<{ tagName: string; id: string }>) {
  const activeHeaderId = useArticleActiveHeaderId()
  const navigate = useNavigate()
  function handleLinkClick(e) {
    e.preventDefault()
    navigate(`#${id}`)
  }

  return (
    <li data-level={tagName}>
      <a
        href={`#${id}`}
        data-active={activeHeaderId === id}
        onClick={handleLinkClick}
      >
        {children}
      </a>
    </li>
  )
}

function getHeadersFromMarkdown(text: string) {
  return text
    .split('\n')
    .filter((x) => x.startsWith('#'))
    .join('\n')
}

function normalizeHeaders(text: string) {
  const textArray = text.split('\n')

  const highersHeaderLevel = textArray.reduce((acc, x) => {
    return Math.min(acc, x.split(' ')[0].length)
  }, 6)

  if (highersHeaderLevel > 2) {
    const diff = highersHeaderLevel - 2
    return textArray.map((x) => x.slice(diff)).join('\n')
  }

  return text
}
