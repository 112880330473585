import { useDataSource } from '@integration-app/react'
import { UNIFIED_DATA_MODELS, buildDataSchema } from '@integration-app/sdk'

import { ConfigCard } from 'components/Card/ConfigCard'
import { RadioGroup } from 'components/RadioGroup'
import { Code } from 'ui-kit/code'
import { Text } from 'ui-kit/text'

import { CustomImportFieldMapping } from './CustomImportFieldMapping'
import { IncludeRawFieldsBlock } from './IncludeRawFieldsBlock'
import { SharedImportFieldMapping } from './SharedImportFieldMapping'
import { useGenericConfig } from '../contexts/generic-config-context'

enum ImportFieldMappingUnitConfigOption {
  None = 'none',
  Custom = 'custom',
  Shared = 'shared',
}

export function IntegrationLevelImportFieldMappingUnitConfig() {
  const {
    patchConfig,
    patchFieldMappingConfig,
    fieldMappingConfig,
    dataCollectionSpec,
    parentConfig,
  } = useGenericConfig()

  async function handleSelectOption({
    value,
  }: {
    value: ImportFieldMappingUnitConfigOption
  }) {
    switch (value) {
      case ImportFieldMappingUnitConfigOption.None:
        return patchConfig({
          fieldMapping: undefined,
        })
      case ImportFieldMappingUnitConfigOption.Custom:
        return patchConfig({
          fieldMapping: {
            defaultValue: fieldMappingConfig.defaultValue ?? {},
          },
        })
      case ImportFieldMappingUnitConfigOption.Shared:
        return patchConfig({
          fieldMapping: {
            key: null,
          },
        })
    }
  }

  const { dataSource: parentDataSource } = useDataSource(
    parentConfig?.dataSource?.key
      ? {
          key: parentConfig?.dataSource?.key,
        }
      : undefined,
  )

  let fieldsSchema = {
    type: 'object',
    additionalProperties: true,
  }

  const parentUdm = parentDataSource?.udm ?? parentConfig?.dataSource?.udm

  if (parentUdm && parentConfig?.fieldMapping?.defaultUnifiedValue) {
    // If this action inherits from a universal action, use the parent's fields schema as a guide
    // for the field mapping.
    const parentFieldsSchema = buildDataSchema(
      parentConfig?.fieldMapping?.defaultUnifiedValue,
      UNIFIED_DATA_MODELS[parentUdm]?.fieldsSchema,
    )

    if (parentFieldsSchema) {
      fieldsSchema = structuredClone(parentFieldsSchema)
      // But allow additional properties
      fieldsSchema.additionalProperties = true
    }
  }

  let selectedOption = ImportFieldMappingUnitConfigOption.None

  if (fieldMappingConfig?.defaultValue !== undefined) {
    selectedOption = ImportFieldMappingUnitConfigOption.Custom
  }

  if (fieldMappingConfig?.key !== undefined) {
    selectedOption = ImportFieldMappingUnitConfigOption.Shared
  }

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>Field Mapping</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content display={'flex'} flexDirection={'column'} gap={4}>
        <Text m={0}>
          Configure the contents of the <Code>fields</Code> property of data
          records returned by this action.
        </Text>
        <RadioGroup.Root
          gap={1}
          value={selectedOption}
          onValueChange={handleSelectOption}
        >
          <RadioGroup.Item value={ImportFieldMappingUnitConfigOption.Custom}>
            Map to a custom schema
          </RadioGroup.Item>
          <RadioGroup.Item value={ImportFieldMappingUnitConfigOption.Shared}>
            Use shared field mapping config
          </RadioGroup.Item>
          <RadioGroup.Item value={ImportFieldMappingUnitConfigOption.None}>
            Keep original fields returned from a connector
          </RadioGroup.Item>
        </RadioGroup.Root>

        {selectedOption === ImportFieldMappingUnitConfigOption.Shared && (
          <SharedImportFieldMapping />
        )}

        {selectedOption === ImportFieldMappingUnitConfigOption.Custom && (
          <CustomImportFieldMapping
            value={fieldMappingConfig?.defaultValue}
            onChange={(defaultValue) =>
              patchFieldMappingConfig({ defaultValue })
            }
            internalFieldsSchema={fieldsSchema}
            externalFieldsSchema={dataCollectionSpec?.fieldsSchema}
          />
        )}

        {selectedOption !== ImportFieldMappingUnitConfigOption.None && (
          <IncludeRawFieldsBlock />
        )}
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}
