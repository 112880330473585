import { useMemo } from 'react'
import {
  DataSource,
  FieldMapping,
  FlowDependencyType,
  UNIFIED_DATA_MODELS,
} from '@integration-app/sdk'
import { useDeepMemo } from '@integration-app/ui/hooks/useDeepEffect'

import { useFlowNode } from 'components/FlowBuilder/context/flow-node-context'
import useFlowVariables from 'components/FlowBuilder/useFlowVariables'
import { useGenericFlow } from 'components/FlowBuilder/useGenericFlow'

import {
  ConfigurationErrorType,
  NodeConfigurationErrorData,
  NodeConfigurationErrorsGetter,
} from './types'
import { useDataCollectionsInFlowNodes } from './useDataCollectionsInFlowNodes'
import { CONSOLE_NODE_SPECS } from '../index'

export function useConfigurationErrors() {
  const { flow } = useGenericFlow()

  const dataCollectionSpecs = useDataCollectionsInFlowNodes(flow)

  const calculationDependencies = JSON.stringify([
    flow?.nodes ?? {},
    flow?.dependencies ?? [],
    dataCollectionSpecs,
  ])

  const nodes = flow.nodes ?? {}

  const { getNodeRunTimeVariablesSchema, getNodeInputSchema } =
    useFlowVariables()

  const nodesErrors: Record<string, NodeConfigurationErrorData[]> =
    useMemo(() => {
      const result = {}

      for (const key in nodes) {
        const node = nodes[key]

        if (!node?.type) {
          // allowed in universal flow
          if (!flow.universalFlowId) {
            continue
          }

          result[key] = [getMissingTypeError()]
          continue
        }

        const config = node.config ?? {}

        const runTimeVariablesSchema = getNodeRunTimeVariablesSchema(key)
        const inputSchema = getNodeInputSchema(key)

        const fieldMapping: FieldMapping | undefined = config.fieldMapping?.key
          ? // FIXME: strictNullCheck temporary fix
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            (flow.dependencies.find(
              (dep) =>
                dep.type === FlowDependencyType.FieldMapping &&
                dep.element?.key === config.fieldMapping.key,
            )?.element as FieldMapping)
          : undefined

        const dataSource: DataSource | undefined = config.dataSource?.key
          ? // FIXME: strictNullCheck temporary fix
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            (flow.dependencies.find(
              (dep) =>
                dep.type === FlowDependencyType.DataSource &&
                dep.element?.key === config.dataSource.key,
            )?.element as DataSource)
          : undefined

        const udm = dataSource?.udm ?? config.dataSource?.udm
        const defaultPath =
          dataSource?.defaultPath ?? config.dataSource?.defaultPath

        const udmSpec = UNIFIED_DATA_MODELS[udm]

        const dataCollectionSpec = dataCollectionSpecs.find(
          (spec) => spec.path === defaultPath,
        )?.spec

        const getters: NodeConfigurationErrorsGetter[] =
          CONSOLE_NODE_SPECS[node.type]?.errorsGetters ?? []

        result[key] = getters
          .map((getter) =>
            getter?.({
              flow,
              node,
              nodeKey: key,
              config,

              dataSource,

              fieldMapping,

              defaultPath,
              dataCollectionSpec,

              udm,
              udmSpec,

              runTimeVariablesSchema,
              inputSchema,
            }),
          )
          .flat()
      }

      return result
    }, [calculationDependencies])

  function getNodeErrors(key: string): NodeConfigurationErrorData[] {
    return nodesErrors[key] ?? []
  }

  function getNodeErrorsFieldLocators(key: string) {
    return getNodeErrors(key)
      .map(({ valueLocator }) => valueLocator)
      .filter(Boolean)
  }

  const nodesWithErrors = useMemo(() => {
    const result = {}

    for (const key in nodesErrors) {
      if (nodesErrors[key].length > 0) {
        result[key] = nodes[key]
      }
    }

    return result
  }, [calculationDependencies])

  return useDeepMemo(
    () => ({
      getNodeErrorsFieldLocators,
      getNodeErrors,
      nodesWithErrors,
      nodesErrors,
    }),
    [nodesErrors, nodesWithErrors],
  )
}

export function useNodeConfigurationErrors() {
  const { nodeKey } = useFlowNode()
  const { getNodeErrors, getNodeErrorsFieldLocators } = useConfigurationErrors()

  return {
    errors: getNodeErrors(nodeKey),
    errorFieldsLocators: getNodeErrorsFieldLocators(nodeKey),
  }
}

function getMissingTypeError(): NodeConfigurationErrorData {
  return {
    type: ConfigurationErrorType.MissingType,
    message: 'Node type is missing',
  }
}
