import { ConfigCard } from 'components/Card/ConfigCard'
import { Code } from 'ui-kit/code'
import { Text } from 'ui-kit/text'

import { CustomImportFieldMapping } from './CustomImportFieldMapping'
import { useGenericConfig } from '../contexts/generic-config-context'

export function ConnectionLevelImportFieldMappingUnitConfig() {
  const { patchFieldMappingConfig, fieldMappingConfig, dataCollectionSpec } =
    useGenericConfig()

  const fieldsSchema = {
    type: 'object',
    additionalProperties: true,
  }

  const isSharedFieldMapping = !!fieldMappingConfig?.key

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>Field Mapping</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content display={'flex'} flexDirection={'column'} gap={4}>
        <Text m={0}>
          Configure the contents of the <Code>fields</Code> property of data
          records returned by this action.
        </Text>

        {isSharedFieldMapping ? (
          <Text m={0}>
            This step uses a shared field mapping config. Please edit it there.
          </Text>
        ) : (
          <CustomImportFieldMapping
            value={fieldMappingConfig?.importValue}
            onChange={(importValue) => patchFieldMappingConfig({ importValue })}
            internalFieldsSchema={fieldsSchema}
            externalFieldsSchema={dataCollectionSpec?.fieldsSchema}
          />
        )}
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}
