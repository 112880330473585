import { useEffect, useState } from 'react'
import { Cell, Row, SimpleInput, AdvancedOptions } from '@integration-app/react'
import {
  CreateDataSourceRequest,
  DataSource,
  DEFAULT_FULL_SYNC_INTERVAL_SECONDS,
  DEFAULT_PULL_UPDATES_INTERVAL_SECONDS,
} from '@integration-app/sdk'
import useEventStopPropagation from '@integration-app/ui/hooks/useEventStopPropagation'
import { toHeaderCase } from 'js-convert-case'

import UdmSelect from 'components/DataSources/UdmSelect'
import { IntegrationComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/IntegrationComboboxSelect'
import useIntegrationElement from 'components/IntegrationElements/hooks/useIntegrationElement'
import { useSyncKeyWithName } from 'components/IntegrationElements/hooks/useSyncKeyWithName'
import { CreateSmthPopup } from 'components/Popup/CreateSmthPopup'
import { VStack } from 'styled-system/jsx'
import { Button } from 'ui-kit/button'

import { CreateDataSourcePopupTitle } from './CreateDataSourcePopupTitle'

export function StepCreateDataSource({
  title,
  onBack,
  onCreate,
  integrationId,
  readOnlyIntegration,
  draftData,
  universal,
}: {
  title?: string
  onBack?: () => void
  onCreate(item: DataSource): void
  integrationId?: string
  readOnlyIntegration?: boolean
  draftData?: Pick<CreateDataSourceRequest, 'name' | 'udm' | 'key'>
  universal?: boolean
}) {
  const formId = 'create-data-source-form'
  const { createItem } = useIntegrationElement<DataSource>({
    route: 'data-sources',
  })
  const [item, setItem] = useState<Partial<CreateDataSourceRequest>>({
    integrationId,
    ...draftData,
  })

  function handleChange(data: Partial<CreateDataSourceRequest>) {
    setItem((prevItem) => ({ ...prevItem, ...data }))
  }

  const handleNameChange = useSyncKeyWithName(handleChange)

  async function handleCreate() {
    const createdDataSource = await createItem({ ...item })
    onCreate(createdDataSource)
  }

  const isCreateDataValid = !!item.key && !!item.name

  useEffect(() => {
    if (item.udm && !item.name && !item.key) {
      void handleNameChange(toHeaderCase(item.udm))
    } else if (!item.udm && item.name) {
      void handleNameChange(item.name)
    }
  }, [item.udm])

  return (
    <>
      <CreateSmthPopup.Header>
        <CreateDataSourcePopupTitle
          onBack={onBack}
          integrationId={item.integrationId}
        >
          {title}
        </CreateDataSourcePopupTitle>
      </CreateSmthPopup.Header>
      <CreateSmthPopup.Content>
        <form id={formId} onSubmit={useEventStopPropagation(handleCreate)}>
          <VStack gap={2} alignItems={'stretch'}>
            <SimpleInput
              label={'Name'}
              value={item.name}
              onChange={handleNameChange}
              autoFocus
            />

            <SimpleInput
              label={'Key'}
              value={item.key}
              onChange={(key) => handleChange({ key })}
            />

            {integrationId && (
              <Row>
                <Cell.Name>Integration</Cell.Name>
                <Cell.Input grow>
                  <IntegrationComboboxSelect
                    variant={'ghost'}
                    width={'full'}
                    marginBlock={-1}
                    useId
                    value={item.integrationId}
                    onChange={(integrationId?: string) =>
                      handleChange({ integrationId })
                    }
                    disabled={readOnlyIntegration}
                  />
                </Cell.Input>
              </Row>
            )}

            <SelectUdmRow
              udm={draftData?.udm}
              universal={universal}
              // FIXME: strictNullCheck temporary fix
              // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
              value={item.udm}
              onChange={handleChange}
            />

            <AdvancedOptions>
              <SimpleInput
                label={'Pull Updates Interval'}
                placeholder={`Default: ${DEFAULT_PULL_UPDATES_INTERVAL_SECONDS}`}
                value={item.pullUpdatesIntervalSeconds?.toString()}
                onChange={(value) =>
                  handleChange({
                    pullUpdatesIntervalSeconds: Number(value) ?? undefined,
                  })
                }
              />

              <SimpleInput
                label={'Full Sync Interval'}
                placeholder={`Default: ${DEFAULT_FULL_SYNC_INTERVAL_SECONDS}`}
                value={item.fullSyncIntervalSeconds?.toString()}
                onChange={(value) =>
                  handleChange({
                    fullSyncIntervalSeconds: Number(value) ?? undefined,
                  })
                }
              />
            </AdvancedOptions>
          </VStack>
        </form>
      </CreateSmthPopup.Content>
      <CreateSmthPopup.Footer>
        <Button disabled={!isCreateDataValid} type={'submit'} form={formId}>
          Create Data Source
        </Button>
      </CreateSmthPopup.Footer>
    </>
  )
}

function SelectUdmRow({
  udm,
  universal,
  value,
  onChange,
}: {
  udm?: string
  universal?: boolean
  value: string
  onChange: (value: Partial<CreateDataSourceRequest>) => void
}) {
  if (udm) {
    return (
      <UdmSelect
        value={value}
        onChange={(udm) => onChange({ udm: udm ?? undefined })}
      />
    )
  }

  if (universal && !udm) {
    return (
      <Row>
        <Cell.Name>UDM</Cell.Name>
        <Cell.Value>Any Data</Cell.Value>
      </Row>
    )
  }

  return null
}
