import { makeDataField } from '@integration-app/react'

import AdminDataBuilderForm from 'components/AdminDataBuilderForm'
import { ConfigCard } from 'components/Card/ConfigCard'

import { useGenericConfig } from './contexts/generic-config-context'
import { useNodeConfigurationErrors } from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors'
import { getBrokenVariablesConfigurationErrors } from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/getBrokenVariablesConfigurationErrors'
import {
  ConfigurationErrorType,
  NodeConfigurationErrorData,
} from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/types'

export function HttpRequestConfig() {
  const { patchConfig, config, variablesSchema } = useGenericConfig()

  const { errorFieldsLocators } = useNodeConfigurationErrors()

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>Request Parameters</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content>
        <AdminDataBuilderForm
          // FIXME: strictNullCheck temporary fix
          // @ts-expect-error TS(2322): Type '(string | undefined)[]' is not assignable to... Remove this comment to see the full error message
          errorFieldsLocators={errorFieldsLocators}
          onChange={(request) => patchConfig({ request })}
          field={makeDataField({
            value: config.request,
            schema: HTTP_REQUEST_INPUT_SCHEMA,
            variablesSchema,
          })}
        />
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}

const HTTP_REQUEST_INPUT_SCHEMA = {
  type: 'object',
  properties: {
    uri: { type: 'string' },
    method: {
      type: 'string',
      enum: ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'],
    },
    body: {
      title: 'Body',
    },
    query: {
      type: 'object',
      section: true,
      additionalProperties: true,
    },
    headers: {
      type: 'object',
      section: true,
      additionalProperties: true,
    },
  },
}

export function getHttpRequestConfigErrors({ config, runTimeVariablesSchema }) {
  const errors: NodeConfigurationErrorData[] = []

  const uri = config?.request?.uri
  if (!uri) {
    errors.push({
      type: ConfigurationErrorType.MissingFieldValue,
      message: 'URI is required.',
      valueLocator: '$.uri',
    })
  }

  errors.push(
    ...getBrokenVariablesConfigurationErrors(config?.request, [
      runTimeVariablesSchema,
    ]),
  )

  return errors
}
