import { FlowNode } from '@integration-app/sdk'
import { uuid4 } from '@sentry/utils'
import { Edge, getIncomers, getOutgoers, Node } from 'reactflow'

import { RegularNodeData } from 'components/FlowBuilder/Graph/nodes/RegularNode/RegularNode'
import { TriggerNodeData } from 'components/FlowBuilder/Graph/nodes/TriggerNode/TriggerNode'
import { isTriggerNode } from 'components/FlowBuilder/isTriggerNode'

import {
  getNodeSize,
  getSubFlowRootNode,
  isNodeWithSubFlow,
} from '../new-layout'

import { GraphEdgeType, GraphNodeType } from './index'

const DEFAULT_NODE_POSITION = {
  x: 0,
  y: 0,
}

const ROOT_NODE_ID = '[root-node]: ' + uuid4()

/*
 * Builds react-flow nodes and edges from flow. Also adds portals, placeholders and root virtual node.
 */
export function buildElements(
  iNodes: Record<string, FlowNode>,
  withPlaceholders: boolean,
): {
  nodes: Node[]
  edges: Edge[]
} {
  // build initial edges
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2322): Type '{ id: string; source: string; target: string... Remove this comment to see the full error message
  const edges: Edge[] = Object.entries(iNodes)
    .map(([key, flowNode]) => {
      const source = key
      const links = flowNode.links || []

      if (withPlaceholders) {
        return links
          .filter((link) => {
            // filtering links to non-existing nodes
            return !(link.key && !iNodes[link.key])
          })
          .map((link, index) => {
            const target = link.key ?? uuid4() // create unique id is key now provided, will be used later to create placeholder
            const id = buildEdgeId(source, target)
            const type = !!link.key
              ? GraphEdgeType.Edge
              : GraphEdgeType.EdgeToNodeOrLinkPlaceholder

            // NOTE: index is used when there is no key, so we can add node to the correct empty link
            const data = { link, index }

            return { id, source, target, type, data }
          })
      } else {
        return links
          .filter((link) => !!link.key)
          .map((link) => {
            const target = link.key
            // FIXME: strictNullCheck temporary fix
            // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
            const id = buildEdgeId(source, target)
            const type = GraphEdgeType.Edge
            const data = { link }

            return { id, source, target, type, data }
          })
      }
    })
    .flat()

  // build initial nodes
  const nodes: Node[] = Object.entries(iNodes).map(([key, flowNode]) => {
    const type = isTriggerNode(flowNode)
      ? GraphNodeType.Trigger
      : GraphNodeType.Node

    const id = key
    const data = { flowNode, flowNodeKey: key }
    const position = DEFAULT_NODE_POSITION

    return {
      id,
      type,
      data,
      position,
    }
  })

  // breaking complex branches into portals
  fillWithPortals(nodes, edges, iNodes)

  // adding necessary placeholders
  if (withPlaceholders) fillWithPlaceholders(nodes, edges, iNodes)

  // constructForEachNodes(nodes, edges)

  // root node is used to connect all top-level nodes without parents to have a single entry point
  fillWithRootNode(nodes, edges)

  // updating nodes sizes
  nodes.forEach((node) => {
    const { height, width } = getNodeSize(node, nodes, edges)

    node.height = height
    node.width = width
  })

  // updating edges zIndex
  edges.forEach((edge) => {
    edge.zIndex = 10000 // setting high zIndex to make sure that edges are always rendered above nodes (important for edges inside subflows)
  })

  updateNodesZIndex(1, getRootNode(nodes), nodes, edges)

  return {
    edges,
    nodes,
  }
}

function getRootNode(nodes: Node[]): Node {
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2322): Type 'Node<any, string | undefined> | undefined' i... Remove this comment to see the full error message
  return nodes.find(
    (node) => node.type === GraphNodeType.Root && node.id === ROOT_NODE_ID,
  )
}

function updateNodesZIndex(
  zIndex: number,
  node: Node,
  nodes: Node[],
  edges: Edge[],
) {
  node.zIndex = zIndex

  const children = getOutgoers(node, nodes, edges)
  children.forEach((child) => {
    // we keep the same zIndex for children because nodes do not overlap
    updateNodesZIndex(zIndex, child, nodes, edges)
  })

  if (isNodeWithSubFlow(node)) {
    const subFlowRoot = getSubFlowRootNode(node, nodes)

    if (subFlowRoot) {
      // but once we inside a sub flow we need to increase zIndex for all nodes inside of it
      // so that they are rendered above the parent node
      updateNodesZIndex(zIndex + 1, subFlowRoot, nodes, edges)
    }
  }
}

function fillWithRootNode(nodes: Node[], edges: Edge[]) {
  const rootNode: Node = {
    id: ROOT_NODE_ID,
    type: GraphNodeType.Root,
    position: { x: 0, y: 0 },
    height: 1,
    width: 1,
    data: {},
    hidden: true,
  }

  const rootNodesOfSubFlows = nodes
    .filter(isNodeWithSubFlow)
    .map((nodeWithSubFlow) => getSubFlowRootNode(nodeWithSubFlow, nodes))
    .filter((node) => !!node)

  // find nodes without parents
  const edgesFromRootNode: Edge[] = nodes
    .filter((node) => getIncomers(node, nodes, edges).length === 0)
    .filter((node) => !rootNodesOfSubFlows.map((n) => n?.id).includes(node.id)) // root nodes of sub flows also don't have parents, but we don't want to bind them to root node
    .map((node) => {
      const source = ROOT_NODE_ID
      const target = node.id
      const id = buildEdgeId(source, target)

      return {
        id,
        source,
        target,
        type: 'default',
      }
    })

  edges.push(...edgesFromRootNode)
  nodes.push(rootNode)
}

function fillWithPortals(
  nodes: Node[],
  edges: Edge[],
  iNodes: Record<string, FlowNode>,
) {
  const edgesIdsToBeRemoved = []

  const regularNodesWithParents = nodes
    .filter((node) => node.type === GraphNodeType.Node)
    .filter((node) => {
      return getIncomers(node, nodes, edges).length > 0
    }) as Node<RegularNodeData>[]

  regularNodesWithParents.forEach((node) => {
    const incomingEdges = edges.filter((edge) => edge.target === node.id)

    if (incomingEdges.length < 2) {
      return
    }

    const secondaryEdges = incomingEdges.slice(1)

    // 2. store edges ids to remove them later
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    edgesIdsToBeRemoved.push(...secondaryEdges.map((edge) => edge.id))

    // 3. create portal nodes for secondary edges
    secondaryEdges.forEach((edge) => {
      const portalNodeId = buildPortalNodeId(edge.source, edge.target)

      // creating portal node
      nodes.push({
        id: portalNodeId,
        type: GraphNodeType.Portal,
        data: {
          parentFlowNodeKey: edge.source,
          parentFlowNode: iNodes[edge.source],
          portalFlowNodeKey: node.data.flowNodeKey,
          portalFlowNode: node.data.flowNode,
        },
        position: DEFAULT_NODE_POSITION,
      })
      // creating edge from parent node to portal node
      edges.push({
        id: buildEdgeId(edge.source, portalNodeId),
        source: edge.source,
        target: portalNodeId,
        type: GraphEdgeType.EdgeToPortal,
        data: {
          child: node.data.flowNodeKey,
          parent: edge.source,
          link: edge.data.link,
        },
      })
    })

    // 4. record portals for target node
    const portalNodesIds = secondaryEdges.map((edge) => edge.source)
    const portalNodes = {}
    Object.entries(iNodes)
      .filter(([key]) => portalNodesIds.includes(key))
      .forEach(([key, flowNode]) => {
        portalNodes[key] = flowNode
      })

    node.data.portalNodes = portalNodes
  })

  // remove secondary edges
  edgesIdsToBeRemoved.forEach((edgeId) => {
    const edgeIndex = edges.findIndex((edge) => edge.id === edgeId)
    edges.splice(edgeIndex, 1)
  })
}

/*
 * Adds placeholders for trigger and regular nodes.
 */
function fillWithPlaceholders(
  nodes: Node[],
  edges: Edge[],
  iNodes: Record<string, FlowNode>,
) {
  // find all regular nodes that have no incoming edges and add trigger placeholders above
  const regularNodes = nodes.filter((node) => node.type === GraphNodeType.Node)

  const rootNodesOfSubFlows = nodes
    .filter(isNodeWithSubFlow)
    .map((nodeWithSubFlow) => getSubFlowRootNode(nodeWithSubFlow, nodes))
    .filter((node) => !!node)

  const regularNodesWithoutParents = regularNodes
    .filter((node) => getIncomers(node, nodes, edges).length === 0)
    .filter((node) => !rootNodesOfSubFlows.map((n) => n?.id).includes(node.id)) // root nodes of subflows also don't have parents, but we don't want to add trigger placeholders above them

  regularNodesWithoutParents.forEach((node) => {
    const triggerPlaceholderId = buildTriggerPlaceholderId(node.id)

    nodes.push({
      id: triggerPlaceholderId,
      type: GraphNodeType.TriggerPlaceholder,
      data: {
        child: node.id,
      },
      position: DEFAULT_NODE_POSITION,
    })

    edges.push({
      id: buildEdgeId(triggerPlaceholderId, node.id),
      source: triggerPlaceholderId,
      target: node.id,
      type: GraphEdgeType.EdgeToNodePlaceholder,
    })
  })

  // find all trigger nodes that have no outgoing edges and add regular nodes placeholders below
  const triggerNodesWithoutChildren = nodes.filter(
    (node) =>
      getOutgoers(node, nodes, edges).length === 0 &&
      node.type === GraphNodeType.Trigger,
  ) as Node<TriggerNodeData>[]

  if (triggerNodesWithoutChildren.length > 0 && regularNodes.length > 0) {
    triggerNodesWithoutChildren.forEach((node) => {
      const nodeOrLinkPlaceholderId = uuid4()
      const hasEmptyLink =
        // FIXME: strictNullCheck temporary fix
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        node.data.flowNode.links.filter((link) => !link.key).length > 0

      if (!hasEmptyLink) {
        edges.push({
          id: buildEdgeId(node.id, nodeOrLinkPlaceholderId),
          source: node.id,
          target: nodeOrLinkPlaceholderId,
          type: GraphEdgeType.EdgeToNodeOrLinkPlaceholder,
        })
      }
    })
  } else {
    triggerNodesWithoutChildren.forEach((node) => {
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const hasLinkPlaceholder = node.data.flowNode.links?.length > 0
      if (hasLinkPlaceholder) return

      const regularPlaceholderId = buildNodePlaceholderId(node.id)

      nodes.push({
        id: regularPlaceholderId,
        type: GraphNodeType.NodePlaceholder,
        data: {
          parent: node.id,
        },
        position: DEFAULT_NODE_POSITION,
      })

      edges.push({
        id: buildEdgeId(node.id, regularPlaceholderId),
        source: node.id,
        target: regularPlaceholderId,
        type: GraphEdgeType.EdgeToNodePlaceholder,
      })
    })
  }

  // if flow is empty then add trigger and regular nodes placeholders
  if (Object.keys(iNodes).length === 0) {
    // add trigger placeholder
    nodes.push({
      id: buildTriggerPlaceholderId(),
      type: GraphNodeType.TriggerPlaceholder,
      data: {
        child: '',
      },
      position: DEFAULT_NODE_POSITION,
    })

    // add regular node placeholder
    nodes.push({
      id: buildNodePlaceholderId(),
      type: GraphNodeType.NodePlaceholder,
      data: {
        parent: '',
      },
      position: DEFAULT_NODE_POSITION,
    })

    // add edge between trigger and regular node placeholders
    edges.push({
      id: buildEdgeId(buildTriggerPlaceholderId(), buildNodePlaceholderId()),
      source: buildTriggerPlaceholderId(),
      target: buildNodePlaceholderId(),
      type: GraphEdgeType.EdgeToNodePlaceholder,
    })
  }

  // fill with placeholders for links that have empty key but filled filter
  const emptyTargetEdges = edges.filter(
    (edge) => edge.type === GraphEdgeType.EdgeToNodeOrLinkPlaceholder,
  )

  emptyTargetEdges.forEach((edge) => {
    // add regular node placeholder
    nodes.push({
      id: edge.target,
      type: GraphNodeType.NodeOrLinkPlaceholder,
      data: {
        parent: edge.source,
        linkIndex: edge.data?.index || 0,
      },
      position: DEFAULT_NODE_POSITION,
    })
  })

  const triggerNodes = nodes.filter(
    (node) => node.type === GraphNodeType.Trigger,
  ) as Node<TriggerNodeData>[]
  const triggerPlaceholders = nodes.filter(
    (node) => node.type === GraphNodeType.TriggerPlaceholder,
  ) as Node<TriggerNodeData>[]
  // add trigger placeholder if at least one trigger exists but no placeholders added yet
  if (triggerNodes.length > 0 && triggerPlaceholders.length === 0) {
    nodes.push({
      id: buildNodePlaceholderId(''),
      type: GraphNodeType.TriggerPlaceholder,
      data: {
        child: '',
      },
      position: DEFAULT_NODE_POSITION,
    })
  }

  // add regular node placeholder for empty forEach nodes
  const subFlowNodesWithEmptyRoot = nodes
    .filter(isNodeWithSubFlow)
    .filter((node) => !getSubFlowRootNode(node, nodes))

  subFlowNodesWithEmptyRoot.forEach((node) => {
    const regularPlaceholderId = buildNodePlaceholderId(node.id)

    // we add node without edge because it will be treated as a part of subgraph
    nodes.push({
      id: regularPlaceholderId,
      type: GraphNodeType.NodePlaceholder,
      data: {
        subgraphParent: node.id,
      },
      position: DEFAULT_NODE_POSITION,
    })
  })
}

function buildTriggerPlaceholderId(child = ''): string {
  return `[trigger-placeholder]: ${child}`
}

function buildNodePlaceholderId(parent = ''): string {
  return `[node-placeholder]: ${parent}`
}

function buildEdgeId(source: string, target: string): string {
  return `[edge]: ${source}-${target}`
}

function buildPortalNodeId(source: string, target: string): string {
  return `[portal]: ${source}-${target}`
}
