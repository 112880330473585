import {
  DataBuilderForm,
  useConnectorSpec,
  makeDataField,
} from '@integration-app/react'
import { ApiRequestSpec } from '@integration-app/sdk'
import { TbPlus, TbTrash } from 'react-icons/tb'
import useSWR from 'swr'

import { IconButton } from 'components/Button/IconButton'
import { ConfigCard } from 'components/Card/ConfigCard'
import { OnOffSwitch } from 'components/Switch/OnOffSwitch'
import { useWorkspace } from 'components/Workspaces/workspace-context'
import { VStack } from 'styled-system/jsx'
import { Button } from 'ui-kit/button'
import { Icon } from 'ui-kit/icon'
import { Text } from 'ui-kit/text'

import { useGenericConfig } from './contexts/generic-config-context'

export function CustomizeImplementation({
  apiRequests,
}: {
  apiRequests?: ApiRequestSpec[]
}) {
  const { config, integrationId, patchConfig } = useGenericConfig()
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
  const { data: connectorSpec, loading } = useConnectorSpec(integrationId)

  if (!integrationId) {
    // Customization is only supported for integration-specific units
    return null
  }

  const isSupported =
    !connectorSpec?.api?.type || connectorSpec?.api?.type == 'openapi'
  const enabled = config?.override !== undefined

  const initialOverridValue: any = {}

  if (apiRequests) {
    initialOverridValue.apiRequests = []
    for (const apiRequest of apiRequests) {
      initialOverridValue.apiRequests.push({
        match: apiRequest,
      })
    }
  }

  return (
    <>
      <ConfigCard.Root>
        <ConfigCard.Header
          rightSlot={
            <OnOffSwitch
              tooltip={'Enable custom API requests'}
              checked={enabled}
              onCheckedChange={({ checked }) => {
                void patchConfig({
                  override: checked ? initialOverridValue : undefined,
                })
              }}
            />
          }
        >
          <ConfigCard.Title>Customize API Requests</ConfigCard.Title>
        </ConfigCard.Header>
        <ConfigCard.Content>
          {!enabled && (
            <Text m={0} fontStyle={'italic'}>
              Turn this on to customize API requests made by this action.
            </Text>
          )}
          {enabled && loading && <Text m={0}>Loading...</Text>}
          {enabled && !loading && !isSupported && (
            <Text m={0}>
              Customization is not available for this connector.
            </Text>
          )}
          {enabled && !loading && isSupported && (
            <Text m={0}>
              Modify API requests made by this action. Parameters you specify
              will be merged with the default request. In case of overlap, your
              parameters will take precedence.
            </Text>
          )}
        </ConfigCard.Content>
      </ConfigCard.Root>
      {enabled && !loading && isSupported && (
        <ConfigCard.NestedWrapper>
          <OpenApiCustomization />
        </ConfigCard.NestedWrapper>
      )}
    </>
  )
}

function OpenApiCustomization() {
  const { config, patchConfig } = useGenericConfig()

  const apiRequests = config.override?.apiRequests ?? [{}]

  async function handleAddRequest() {
    return patchConfig({
      override: {
        apiRequests: [...apiRequests, {}],
      },
    })
  }

  async function handleRequestChange(index, request) {
    const newRequests = [...apiRequests]
    newRequests[index] = request
    return patchConfig({ override: { apiRequests: newRequests } })
  }

  async function handleRequestDelete(index) {
    const newRequests = [...apiRequests]
    newRequests.splice(index, 1)
    return patchConfig({ override: { apiRequests: newRequests } })
  }

  return (
    <>
      {apiRequests.map((request, index) => (
        <OpenApiRequestCustomization
          key={index}
          value={request}
          onChange={(request) => handleRequestChange(index, request)}
          onDelete={() => handleRequestDelete(index)}
        />
      ))}
      <ConfigCard.Root variant={'ghost'} alignSelf={'start'}>
        <Button variant='outline' onClick={handleAddRequest}>
          <Icon>
            <TbPlus />
          </Icon>
          Add request to customize
        </Button>
      </ConfigCard.Root>
    </>
  )
}

function OpenApiRequestCustomization({ value, onChange, onDelete }) {
  const { engineAdminFetcher } = useWorkspace()
  const { integrationId, variablesSchema } = useGenericConfig()

  const path = value?.match?.path
  const method = value?.match?.method
  const patch = value.patch

  const { data: paths } = useSWR(
    integrationId ? `/integrations/${integrationId}/openapi/paths` : undefined,
    engineAdminFetcher,
  )
  const { data: methods } = useSWR(
    integrationId && path
      ? `/integrations/${integrationId}/openapi/path-methods?path=${path}`
      : undefined,
    engineAdminFetcher,
  )
  const { data: requestSchema } = useSWR(
    integrationId && path && method
      ? `/integrations/${integrationId}/openapi/request-schema?path=${path}&method=${method}`
      : undefined,
    engineAdminFetcher,
  )

  return (
    <ConfigCard.Root>
      <ConfigCard.Header
        menuSlot={
          <IconButton
            tooltip={'Delete request'}
            variant={'ghost'}
            size={'sm'}
            onClick={onDelete}
          >
            <TbTrash />
          </IconButton>
        }
      >
        <ConfigCard.Title>{path ?? 'New Request'}</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content display={'flex'} flexDirection={'column'} gap={2}>
        <Text m={0}>When this request is made:</Text>
        <VStack gap={2} alignItems={'stretch'}>
          <DataBuilderForm
            field={makeDataField({
              schema: {
                title: 'Path',
                type: 'string',
                enum: paths,
                allowCustom: true,
                description:
                  'Partial path of the request to override. Use * to match any part of the path (i.e. "/users/*/create").',
              },
              value: path,
            })}
            onChange={(path) => onChange({ ...value, match: { path, method } })}
          />
          <DataBuilderForm
            field={makeDataField({
              schema: {
                title: 'Method',
                type: 'string',
                enum: methods,
                allowCustom: true,
                description: 'HTTP method of the request to override.',
              },
              value: method,
            })}
            frozenFieldsLocators={path ? [] : ['$']}
            onChange={(method) =>
              onChange({ ...value, match: { path, method } })
            }
          />
        </VStack>
        <Text m={0}>...add or replace these parameters:</Text>
        {path && method ? (
          <DataBuilderForm
            field={makeDataField({
              schema: requestSchema || DEFAULT_REQUEST_SCHEMA,
              value: patch,
              variablesSchema,
            })}
            onChange={(patch) =>
              onChange({
                ...value,
                patch,
              })
            }
          />
        ) : (
          <Text m={0} fontStyle={'italic'}>
            Select a path and method to customize.
          </Text>
        )}
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}

const DEFAULT_REQUEST_SCHEMA = {
  type: 'object',
  properties: {
    headers: {
      additionalProperties: true,
      properties: {},
      type: 'object',
    },
    query: {
      additionalProperties: true,
      properties: {},
      type: 'object',
    },
    data: {
      additionalProperties: true,
      properties: {},
      type: 'object',
    },
  },
}
