import { Graph } from 'components/FlowBuilder/Graph'
import {
  GraphEdgeType,
  GraphNodeType,
} from 'components/FlowBuilder/Graph/elements'
import { EdgeWithLink } from 'components/FlowBuilder/Graph/elements/Edge/EdgeWithLink'
import { StaticEdge } from 'components/FlowBuilder/Graph/elements/Edge/StaticEdge'
import { RootNode } from 'components/FlowBuilder/Graph/elements/RootNode'
import { RegularNodeOrLinkPlaceholderNode } from 'components/FlowBuilder/Graph/nodes/NodeOrLinkPlaceholderNode/RegularNodeOrLinkPlaceholderNode'
import { RegularNodePlaceholderNode } from 'components/FlowBuilder/Graph/nodes/NodeOrLinkPlaceholderNode/RegularNodePlaceholderNode'
import { TriggerPlaceholderNode } from 'components/FlowBuilder/Graph/nodes/TriggerPlaceholderNode/TriggerPlaceholderNode'

import { useFlowPreviewContext } from './flow-preview-context'
import { PortalNode } from './PortalNode'
import { RegularOrTriggerNode } from './RegularOrTriggerNode'

const NODE_TYPES = {
  [GraphNodeType.Node]: RegularOrTriggerNode,
  [GraphNodeType.Trigger]: RegularOrTriggerNode,
  [GraphNodeType.Portal]: PortalNode,
  [GraphNodeType.Root]: RootNode,
  [GraphNodeType.TriggerPlaceholder]: TriggerPlaceholderNode,
  [GraphNodeType.NodeOrLinkPlaceholder]: RegularNodeOrLinkPlaceholderNode,
  [GraphNodeType.NodePlaceholder]: RegularNodePlaceholderNode,
}

const EDGE_TYPES = {
  [GraphEdgeType.Edge]: StaticEdge,
  [GraphEdgeType.EdgeToPortal]: StaticEdge,
  [GraphEdgeType.EdgeToNodeOrLinkPlaceholder]: EdgeWithLink,
  [GraphEdgeType.EdgeToNodePlaceholder]: EdgeWithLink,
}

export function FlowPreviewGraph() {
  const { flowNodes } = useFlowPreviewContext()

  return (
    <Graph
      usePlaceholders
      withBackground={false}
      flowNodes={flowNodes}
      nodeTypes={NODE_TYPES}
      edgeTypes={EDGE_TYPES}
      fitOnNodesChange
    />
  )
}
