import { forwardRef, HTMLAttributes, PropsWithChildren } from 'react'

import classes from './styles.module.css'

export const DocFullPageArticle = forwardRef<
  HTMLDivElement,
  PropsWithChildren<HTMLAttributes<HTMLDivElement>>
>(({ children, ...props }, ref) => {
  return (
    <div className={classes.content_main} {...props} ref={ref}>
      <div className={classes.articleContent}>{children}</div>
    </div>
  )
})
