import { FieldDescription } from '@integration-app/react'
import { UNIFIED_DATA_MODELS } from '@integration-app/sdk'
import { TbPlus } from 'react-icons/tb'

import AdminDataBuilderForm from 'components/AdminDataBuilderForm'
import { ConfigCard } from 'components/Card/ConfigCard'
import { Button } from 'ui-kit/button'
import { Icon } from 'ui-kit/icon'

import { makeFieldForSchema } from './helper'
import { useFieldMappingContext } from '../contexts/field-mapping-context'

export function FrozenExportFieldsConfig() {
  const {
    fieldMapping,
    isUniversal,
    dataSource,
    dataCollectionSpec,
    onChange,
  } = useFieldMappingContext()

  const externalSchema = isUniversal
    ? dataSource?.udm
      ? UNIFIED_DATA_MODELS[dataSource?.udm]?.fieldsSchema
      : undefined
    : dataCollectionSpec?.fieldsSchema

  return (
    <>
      {!fieldMapping.frozenExportFields && (
        <ConfigCard.Root
          variant={'ghost'}
          display={'flex'}
          flexDirection={'row'}
          gap={4}
        >
          <Button
            size={'dense'}
            variant={'outline'}
            onClick={(value) => {
              void onChange(
                value
                  ? { frozenExportFields: [undefined] }
                  : { frozenExportFields: undefined },
              )
            }}
          >
            <Icon size={'sm'}>
              <TbPlus />
            </Icon>
            Add Frozen Fields
          </Button>
        </ConfigCard.Root>
      )}

      {fieldMapping.frozenExportFields && (
        <ConfigCard.Root>
          <ConfigCard.Header>
            <ConfigCard.Title>Frozen Fields</ConfigCard.Title>
            <FieldDescription
              text={
                'Enable to freeze some of the mapped fields and make them not editable by your customers.'
              }
            />
          </ConfigCard.Header>
          <ConfigCard.Content>
            <AdminDataBuilderForm
              field={makeFieldForSchema(
                externalSchema,
                fieldMapping.frozenExportFields ?? [],
              )}
              onChange={(frozenExportFields) =>
                onChange({ frozenExportFields })
              }
            />
          </ConfigCard.Content>
        </ConfigCard.Root>
      )}
    </>
  )
}

export function FrozenImportFieldsConfig() {
  const { fieldMapping, appSchema, onChange } = useFieldMappingContext()

  return (
    <>
      {!fieldMapping.frozenImportFields && (
        <ConfigCard.Root
          variant={'ghost'}
          display={'flex'}
          flexDirection={'row'}
          gap={4}
        >
          <Button
            size={'dense'}
            variant={'outline'}
            onClick={(value) => {
              void onChange(
                value
                  ? { frozenImportFields: [undefined] }
                  : { frozenImportFields: undefined },
              )
            }}
          >
            <Icon size={'sm'}>
              <TbPlus />
            </Icon>
            Add Frozen Fields
          </Button>
        </ConfigCard.Root>
      )}

      {fieldMapping.frozenImportFields && (
        <ConfigCard.Root>
          <ConfigCard.Header>
            <ConfigCard.Title>Frozen Fields</ConfigCard.Title>
            <FieldDescription
              text={
                'Enable to freeze some of the mapped fields and make them not editable by your customers.'
              }
            />
          </ConfigCard.Header>
          <ConfigCard.Content>
            <AdminDataBuilderForm
              field={makeFieldForSchema(
                appSchema,
                fieldMapping.frozenImportFields ?? [],
              )}
              onChange={(frozenImportFields) =>
                onChange({ frozenImportFields })
              }
            />
          </ConfigCard.Content>
        </ConfigCard.Root>
      )}
    </>
  )
}
