import { FlowNodeLink } from '@integration-app/sdk'
import { EdgeProps } from 'reactflow'

import clsx from 'utils/clsx'

import classes from './Edge.module.css'
import { LinkNameOnEdgeCenter } from './LinkNameOnEdgeCenter'
import { useEdgePath } from './useEdgeProps'

export interface StaticEdgeData {
  link?: FlowNodeLink
  index?: number
}

export function StaticEdge(props: EdgeProps<StaticEdgeData>) {
  const { id, markerEnd, style = {}, data, source, targetX, targetY } = props

  const path = useEdgePath(props)

  return (
    <g>
      <path
        id={id}
        d={path}
        markerEnd={markerEnd}
        className={clsx(classes.staticEdgeLine, 'react-flow__edge-path')}
        style={{
          ...style,
        }}
      />

      <LinkNameOnEdgeCenter
        x={targetX}
        y={targetY - 40}
        link={data?.link}
        nodeKey={source}
        interactive={false}
      />
    </g>
  )
}
