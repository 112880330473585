import { Hit } from 'instantsearch.js'
import { Snippet } from 'react-instantsearch'

import DocLink from 'components/Docs/DocLink'
import DocsBreadcrumbs from 'routes/Docs/components/DocBreadcrumbsFromPath'
import { removePrefixNumberFromPath } from 'routes/Docs/helpers/helpers'
import { sva } from 'styled-system/css'
import { Heading } from 'ui-kit/heading'

const algoliaSearchResultRecipe = sva({
  slots: ['root', 'header', 'headerLink', 'description', 'breadcrumbs'],
  base: {
    root: {
      paddingInline: 5,
      paddingBlock: 4,
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      position: 'relative',
      isolation: 'isolate',
      _hover: {
        backgroundColor: 'bg.subtle',
      },
      '& + &': {
        borderBlockStartWidth: '1',
        borderBlockStartColor: 'border.subtle',
      },
    },
    header: {
      textStyle: 'lg',
      margin: 0,
    },
    headerLink: {
      // eslint-disable-next-line @pandacss/no-hardcoded-color
      color: 'inherit',
      _after: {
        content: '""',
        position: 'absolute',
        inset: 0,
        zIndex: 'base',
        cursor: 'pointer',
      },
    },
    description: {
      pointerEvents: 'none',
    },
    breadcrumbs: {
      '& a': {
        color: 'fg.muted',
        zIndex: 'docked',
      },
    },
  },
})
const algoliaSearchResultClassnames = algoliaSearchResultRecipe({})

export function AlgoliaSearchResult({ hit }: { hit: Hit }) {
  const { objectID, title } = hit

  const path = removePrefixNumberFromPath(objectID)

  return (
    <div className={algoliaSearchResultClassnames.root} key={objectID}>
      <Heading as='h2' className={algoliaSearchResultClassnames.header}>
        <DocLink
          className={algoliaSearchResultClassnames.headerLink}
          path={path}
        >
          {title}
        </DocLink>
      </Heading>

      <div className={algoliaSearchResultClassnames.description}>
        {/* NOTE: Snippet length is configured through `attributesToSnippet` setting here https://www.algolia.com/apps/Q9GPIDDV0Q/explorer/configuration/docs/snippeting. */}
        {/* See more info in algolia docs https://www.algolia.com/doc/guides/building-search-ui/ui-and-ux-patterns/highlighting-snippeting/react/#snippeting */}
        <Snippet hit={hit} attribute='text' highlightedTagName='b' />
      </div>

      <div className={algoliaSearchResultClassnames.breadcrumbs}>
        <DocsBreadcrumbs path={path} />
      </div>
    </div>
  )
}
