import {
  DataCollectionSelect,
  DataSourceConfigureEvents,
} from '@integration-app/react'

import { ConfigCard } from 'components/Card/ConfigCard'
import { Text } from 'ui-kit/text'

import { useGenericConfig } from '../contexts/generic-config-context'

export function ConnectionLevelDataSourceUnitConfig({
  configureEvents,
}: {
  configureEvents: boolean
}) {
  const { dataSourceConfig, patchConfig, variablesSchema } = useGenericConfig()

  const isSharedDataSource = !!dataSourceConfig?.key

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>Data Source</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content display={'flex'} flexDirection={'column'} gap={2}>
        <Text m={0}>
          Select which data in the external application you want to use in this
          integration.
        </Text>

        {isSharedDataSource ? (
          <Text m={0}>
            This step uses a shared data source config. Please edit it there.
          </Text>
        ) : (
          <>
            <DataCollectionSelect
              value={dataSourceConfig}
              onChange={(dataSource) => patchConfig({ dataSource })}
              variablesSchema={variablesSchema}
            />
            {configureEvents && (
              <DataSourceConfigureEvents
                value={dataSourceConfig}
                onChange={(dataSource) => patchConfig({ dataSource })}
              />
            )}
          </>
        )}
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}
