import React, { useState } from 'react'
import { TbInfoCircle } from 'react-icons/tb'

import { Alert } from 'components/Alert'
import { Alert as UiKitAlert } from 'ui-kit/alert'
import { Button } from 'ui-kit/button'

export function ResetCallOut({
  description,
  reset,
  ...props
}: UiKitAlert.RootProps & {
  description: string
  reset: () => Promise<any>
}) {
  const [resetting, setResetting] = useState(false)

  async function handleReset() {
    setResetting(true)
    await reset()
    setResetting(false)
  }

  return (
    <Alert.Root variant='warning' icon={<TbInfoCircle />} {...props}>
      <Alert.Title>{description}</Alert.Title>
      <Alert.Description>
        <Button
          onClick={handleReset}
          variant='outline'
          size={'sm'}
          loading={resetting}
          disabled={resetting}
        >
          Reset
        </Button>
      </Alert.Description>
    </Alert.Root>
  )
}
