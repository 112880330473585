import { useAppEventType, DataBuilderFilter } from '@integration-app/react'

import { ConfigCard } from 'components/Card/ConfigCard'
import AppEventTypeSelect from 'routes/Workspaces/Workspace/YourApp/AppEventTypes/components/AppEventTypeSelect'
import { Text } from 'ui-kit/text'

import { useGenericConfig } from '../../../../../../../components/common-configs/contexts/generic-config-context'

export function AppEventTrigger() {
  const { config, patchConfig, variablesSchema } = useGenericConfig()
  const appEventConfig = config.appEvent ?? {}

  const { appEventType } = useAppEventType(appEventConfig.key)

  function patchAppEventConfig(value: any) {
    return patchConfig({
      ...config,
      appEvent: {
        ...appEventConfig,
        ...(value ?? {}),
      },
    })
  }

  return (
    <>
      <ConfigCard.Root>
        <ConfigCard.Header>
          <ConfigCard.Title>App Event</ConfigCard.Title>
        </ConfigCard.Header>
        <ConfigCard.Content>
          <Text m={0} marginBlockEnd={2}>
            Select app event this node is triggered by
          </Text>
          <AppEventTypeSelect
            value={appEventConfig.key ?? config.eventKey} // .eventKey is for backwards compatibility
            onChange={(key) => patchAppEventConfig({ key })}
          />
        </ConfigCard.Content>
      </ConfigCard.Root>
      {appEventType && (
        <ConfigCard.NestedWrapper>
          <ConfigCard.Root>
            <ConfigCard.Header>
              <ConfigCard.Title>Filter</ConfigCard.Title>
            </ConfigCard.Header>
            <ConfigCard.Content>
              <Text m={0} marginBlockEnd={2}>
                Only events that match the filter will trigger the flow
              </Text>

              <DataBuilderFilter
                value={config.filter}
                variablesSchema={variablesSchema}
                onChange={(filter) => patchConfig({ filter })}
              />
            </ConfigCard.Content>
          </ConfigCard.Root>
        </ConfigCard.NestedWrapper>
      )}
    </>
  )
}
