import {
  getFlowNodeConfigTimeVariablesSchema,
  getFlowNodeRunTimeVariablesSchema,
} from '@integration-app/sdk'

import { useFlowEditorData } from './context/flow-data-context'
import { useGenericFlow } from './useGenericFlow'

export default function useFlowVariables() {
  const { getUserSchema } = useFlowEditorData()
  const { flow } = useGenericFlow()

  const getNodeInputSchema = (nodeKey: string) => {
    return flow.nodes?.[nodeKey]?.inputSchema
  }
  const getNodeOutputSchema = (nodeKey: string) => {
    return flow.nodes?.[nodeKey]?.outputSchema
  }
  const getNodeOutputExample = (nodeKey: string) => {
    return flow.nodes?.[nodeKey]?.outputExample
  }

  const getNodeInputExample = (nodeKey: string) => {
    nodeKey
    // ToDo: implement
    return null
  }

  const getNodeRunTimeVariablesExample = (nodeKey: string) => {
    // ToDo: implement
    nodeKey
    return null
  }

  const getNodeBatchVariablesExample = (nodeKey: string) => {
    // ToDo: implement
    nodeKey
    return null
  }

  const getNodeRunTimeVariablesSchema = (nodeKey: string): any => {
    return getFlowNodeRunTimeVariablesSchema({
      inputSchema: flow.nodes?.[nodeKey]?.inputSchema,
      userSchema: getUserSchema(),
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2322): Type 'DataSchema | undefined' is not assignable to... Remove this comment to see the full error message
      parametersSchema: flow.parametersSchema,
    })
  }

  const getNodeConfigTimeVariablesSchema = (): any => {
    return getFlowNodeConfigTimeVariablesSchema({
      userSchema: getUserSchema(),
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2322): Type 'DataSchema | undefined' is not assignable to... Remove this comment to see the full error message
      parametersSchema: flow.parametersSchema,
    })
  }

  const getNodeBatchVariablesSchema = (nodeKey: string): any => {
    const schema = getNodeRunTimeVariablesSchema(nodeKey)
    schema.properties.input = {
      type: 'array',
      items: getNodeInputSchema(nodeKey),
    }
    return schema
  }

  return {
    getNodeInputSchema,
    getNodeInputExample,
    getNodeOutputSchema,
    getNodeOutputExample,
    getNodeRunTimeVariablesSchema,
    getNodeConfigTimeVariablesSchema,
    getNodeBatchVariablesSchema,
    getNodeRunTimeVariablesExample,
    getNodeBatchVariablesExample,
  }
}
