import { useState } from 'react'
import { Row, Cell, DataBuilderFilter } from '@integration-app/react'
import { TbChevronRight, TbChevronDown } from 'react-icons/tb'

import { IconButton } from 'components/Button/IconButton'
import { ConfigCard } from 'components/Card/ConfigCard'
import {
  DataInputSelect,
  useDataInput,
} from 'components/FlowBuilder/NodeSidebar/data-input'
import { Checkbox } from 'ui-kit/checkbox'
import { Heading } from 'ui-kit/heading'
import { Text } from 'ui-kit/text'

import { useGenericConfig } from '../../../../../../../components/common-configs/contexts/generic-config-context'
import { getBrokenVariablesConfigurationErrors } from '../configuration-errors/getBrokenVariablesConfigurationErrors'
import { NodeConfigurationErrorsGetter } from '../configuration-errors/types'

export function Filter() {
  const { config, patchConfig, variablesSchema } = useGenericConfig()
  const [expandedCustomFilter, setExpandedCustomFilter] = useState(false)

  const userFilterInput = config.userFilterInput ?? { $var: 'input' }

  const { inputSchema } = useDataInput(userFilterInput)

  function toggleCustomFilter() {
    setExpandedCustomFilter((v) => !v)
  }

  return (
    <>
      <ConfigCard.Root>
        <ConfigCard.Header>
          <ConfigCard.Title>Filter</ConfigCard.Title>
        </ConfigCard.Header>
        <ConfigCard.Content display={'flex'} flexDirection={'column'} gap={2}>
          <Text m={0}>
            Only input matching conditions below will pass to the downstream
            nodes.
          </Text>
          <DataBuilderFilter
            variablesSchema={variablesSchema}
            value={config.filter}
            onChange={(filter: any) => patchConfig({ filter })}
          />
        </ConfigCard.Content>
      </ConfigCard.Root>

      <ConfigCard.Root>
        <ConfigCard.Header
          menuSlot={
            <IconButton
              variant={'ghost'}
              tooltip={expandedCustomFilter ? 'Collapse' : 'Expand'}
              onClick={toggleCustomFilter}
            >
              {expandedCustomFilter ? <TbChevronDown /> : <TbChevronRight />}
            </IconButton>
          }
        >
          <ConfigCard.Title>Customer Filter</ConfigCard.Title>
        </ConfigCard.Header>

        {expandedCustomFilter && (
          <ConfigCard.Content display={'flex'} flexDirection={'column'} gap={2}>
            <Text m={0}>
              You can let customers specify their own filter, additionally to
              the one you configured above. Only inputs that match both your and
              customer's conditions will pass to the downstream nodes.
            </Text>
            <Row>
              <Cell.Name>Allow Customer Configure Filter</Cell.Name>
              <Cell>
                <Checkbox
                  checked={config.userFilterEnabled}
                  onCheckedChange={() =>
                    patchConfig({
                      userFilterEnabled: !config.userFilterEnabled,
                    })
                  }
                />
              </Cell>
            </Row>

            <Heading as={'h3'} textStyle={'md'} m={0} marginBlockStart={2}>
              Filter Input
            </Heading>

            <DataInputSelect
              value={userFilterInput}
              onChange={(userFilterInput: any) =>
                patchConfig({ userFilterInput })
              }
            />

            <Heading as={'h3'} textStyle={'md'} m={0} marginBlockStart={2}>
              Default Customer Filter
            </Heading>

            <DataBuilderFilter
              variablesSchema={inputSchema}
              value={config.defaultUserFilter}
              onChange={(defaultUserFilter) =>
                patchConfig({ defaultUserFilter })
              }
            />
          </ConfigCard.Content>
        )}
      </ConfigCard.Root>
    </>
  )
}

export const getFilterConfigErrors: NodeConfigurationErrorsGetter = ({
  config,
  runTimeVariablesSchema,
}) => {
  const errors = []

  errors.push(
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2345): Argument of type 'NodeConfigurationErrorData' is n... Remove this comment to see the full error message
    ...getBrokenVariablesConfigurationErrors(config.filter, [
      runTimeVariablesSchema,
    ]),
  )

  return errors
}
