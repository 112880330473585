import { PropsWithChildren, useEffect, useState } from 'react'
import { IntegrationAppProvider } from '@integration-app/react'

import { useWorkspace } from 'components/Workspaces/workspace-context'
import { ENV_CONFIG } from 'config/env'

/*
 * IntegrationAppProvider but from user's behalf.
 */
export function IntegrationAppProviderAsCustomer({
  children,
  id,
}: PropsWithChildren<{ id: string }>) {
  const [token, setUserAccessToken] = useState<string | undefined>()

  const { makeAccessToken } = useWorkspace()

  useEffect(() => {
    if (!id) return

    void makeAccessToken(id).then(setUserAccessToken)
  }, [id])

  return (
    <IntegrationAppProvider
      token={token}
      apiUri={ENV_CONFIG.ENGINE_API_URI}
      uiUri={ENV_CONFIG.ENGINE_UI_URI}
    >
      {token && children}
    </IntegrationAppProvider>
  )
}

export function IntegrationAppProviderAsTestCustomer({
  children,
}: PropsWithChildren) {
  const { testCustomerAccessToken } = useWorkspace()

  // Keeping token in state to avoid re-rendering of the whole tree
  const [token, setToken] = useState(testCustomerAccessToken)
  // Only changing the token when workspace changes
  useEffect(() => {
    setToken(testCustomerAccessToken)
  }, [testCustomerAccessToken])

  return (
    <IntegrationAppProvider
      token={token}
      apiUri={ENV_CONFIG.ENGINE_API_URI}
      uiUri={ENV_CONFIG.ENGINE_UI_URI}
    >
      {children}
    </IntegrationAppProvider>
  )
}
