import { Fragment } from 'react'
import { TbChevronRight } from 'react-icons/tb'

import DocLink from 'components/Docs/DocLink'
import { Icon } from 'ui-kit/icon'

import classes from './Breadcrumbs.module.css'

export type DocBreadcrumbType = {
  path?: string
  title: string | JSX.Element
}

export function DocBreadcrumbs({
  breadcrumbs,
}: {
  breadcrumbs?: DocBreadcrumbType[]
}) {
  if (!breadcrumbs?.length || breadcrumbs?.length < 2) {
    return null
  }
  return (
    <nav className={classes.breadcrumbs}>
      {breadcrumbs?.map((breadcrumb, i) => (
        <Fragment key={i}>
          {breadcrumb?.path ? (
            <DocLink className={classes.breadcrumb} path={breadcrumb.path}>
              {breadcrumb.title}
            </DocLink>
          ) : (
            <span className={classes.breadcrumb}>{breadcrumb.title}</span>
          )}

          <Icon size={'xs'} className={classes.breadcrumbIcon}>
            <TbChevronRight />
          </Icon>
        </Fragment>
      ))}
    </nav>
  )
}
