import { useState, useId } from 'react'
import { useIntegration, SimpleInput, Cell, Row } from '@integration-app/react'
import {
  CreateFieldMappingRequest,
  FieldMapping,
  FieldMappingDirection,
} from '@integration-app/sdk'
import useEventStopPropagation from '@integration-app/ui/hooks/useEventStopPropagation'

import { ContentRows } from 'components/ContentRows'
import DataSourceSelect from 'components/DataSources/DataSourceSelect'
import { IntegrationComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/IntegrationComboboxSelect'
import useIntegrationElement from 'components/IntegrationElements/hooks/useIntegrationElement'
import { useSyncKeyWithName } from 'components/IntegrationElements/hooks/useSyncKeyWithName'
import { CreateSmthPopup } from 'components/Popup/CreateSmthPopup'
import { SelectItemType } from 'components/Select'
import SimpleSelect from 'components/SimpleSelect'
import { Button } from 'ui-kit/button'

import { FIELD_MAPPING_DIRECTION_OPTIONS } from '../../FieldMapping/components/FieldMappingConfiguration'

type StepCreateFieldMappingProps = {
  title?: string
  onCreate(item: FieldMapping): void
  onClose(): void
  integrationId?: string
  readOnlyIntegration?: boolean
  dataSourceKey?: string
  readOnlyDataSource?: boolean
  direction?: FieldMappingDirection
}

export function StepCreateFieldMapping({
  title,
  onCreate,
  onClose,
  integrationId,
  readOnlyIntegration,
  dataSourceKey,
  readOnlyDataSource,
  direction,
}: StepCreateFieldMappingProps) {
  const formId = useId()
  const { createItem } = useIntegrationElement<FieldMapping>({
    route: 'field-mappings',
  })
  const [item, setItem] = useState<Partial<CreateFieldMappingRequest>>({
    integrationId,
    dataSourceKey,
    direction,
  })

  function handleChange(data: Partial<CreateFieldMappingRequest>) {
    setItem((prevItem) => ({ ...prevItem, ...data }))
  }

  const handleNameChange = useSyncKeyWithName(handleChange)

  async function handleCreate() {
    const createdFieldMapping = await createItem({ ...item })
    onCreate(createdFieldMapping)
  }

  const isCreateDataValid = !!item.key && !!item.name

  const directionOptions: SelectItemType[] =
    FIELD_MAPPING_DIRECTION_OPTIONS.filter(
      (option) => option.value !== FieldMappingDirection.IMPORT,
    )

  return (
    <CreateSmthPopup.Root open onOpenChange={onClose}>
      <CreateSmthPopup.Window width={'xl'}>
        <CreateSmthPopup.Header>
          <CreateSmthPopup.Title>
            {title || <CreateFlowTitle integrationId={item.integrationId} />}
          </CreateSmthPopup.Title>
        </CreateSmthPopup.Header>
        <CreateSmthPopup.Content>
          <form id={formId} onSubmit={useEventStopPropagation(handleCreate)}>
            <ContentRows>
              <SimpleInput
                label={'Name'}
                value={item.name}
                onChange={handleNameChange}
                autoFocus
              />

              <SimpleInput
                label={'Key'}
                value={item.key}
                onChange={(key) => handleChange({ key })}
              />

              {integrationId && (
                <Row>
                  <Cell.Name>Integration</Cell.Name>
                  <Cell.Input grow>
                    <IntegrationComboboxSelect
                      variant={'ghost'}
                      width={'full'}
                      marginBlock={-1}
                      useId
                      value={item.integrationId}
                      onChange={(integrationId?: string) =>
                        handleChange({ integrationId })
                      }
                      disabled={readOnlyIntegration}
                    />
                  </Cell.Input>
                </Row>
              )}

              {dataSourceKey && (
                <DataSourceSelect
                  integrationId={integrationId}
                  dataSourceKey={dataSourceKey}
                  onChange={(dataSourceKey) => handleChange({ dataSourceKey })}
                  disabled={readOnlyDataSource}
                />
              )}

              {direction && (
                <SimpleSelect
                  name='Direction'
                  value={direction}
                  options={directionOptions}
                  onChange={(direction) =>
                    handleChange({
                      direction: direction as FieldMappingDirection,
                    })
                  }
                />
              )}
            </ContentRows>
          </form>
        </CreateSmthPopup.Content>
        <CreateSmthPopup.Footer>
          <Button form={formId} disabled={!isCreateDataValid} type={'submit'}>
            Create Field Mapping
          </Button>
        </CreateSmthPopup.Footer>
      </CreateSmthPopup.Window>
    </CreateSmthPopup.Root>
  )
}

function CreateFlowTitle({ integrationId }: { integrationId?: string }) {
  const { integration } = useIntegration(integrationId)
  if (!integrationId) {
    return <>Create Universal Field Mapping</>
  }

  if (!integration?.name) {
    return <>Create Field Mapping</>
  }

  return <>Create Field Mapping for {integration?.name}</>
}
