import { makeDataField } from '@integration-app/react'

import AdminDataBuilderForm from 'components/AdminDataBuilderForm'
import { ConfigCard } from 'components/Card/ConfigCard'
import { Text } from 'ui-kit/text'

import { useGenericConfig } from '../../../../../../../components/common-configs/contexts/generic-config-context'
import { getBrokenVariablesConfigurationErrors } from '../configuration-errors/getBrokenVariablesConfigurationErrors'
import { NodeConfigurationErrorsGetter } from '../configuration-errors/types'

export function TransformData() {
  const { variablesSchema, patchConfig, config } = useGenericConfig()

  const field = makeDataField({
    variablesSchema,
    value: config.output,
    schema: {
      title: 'Output',
    },
  })

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>Output</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content>
        <Text m={0} marginBlockEnd={3}>
          Configure data this node returns
        </Text>

        <AdminDataBuilderForm
          field={field}
          onChange={(output) => patchConfig({ output })}
        />
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}

export const getTransformDataNodeErrors: NodeConfigurationErrorsGetter = ({
  config,
  runTimeVariablesSchema,
}) => {
  const errors = []

  const brokenVariablesErrors = getBrokenVariablesConfigurationErrors(
    config?.output,
    [runTimeVariablesSchema],
  )

  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2345): Argument of type 'NodeConfigurationErrorData' is n... Remove this comment to see the full error message
  errors.push(...brokenVariablesErrors)

  return errors
}
