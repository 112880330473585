import { forwardRef } from 'react'
import { SvgIcon, SvgIconType } from '@integration-app/react'
import useEventStopPropagation from '@integration-app/ui/hooks/useEventStopPropagation'

import clsx from 'utils/clsx'

import classes from './NodeBody.module.css'

export const CommonRoundedButton = forwardRef<
  HTMLButtonElement,
  {
    onClick()
    iconType: SvgIconType
    className?: string
    squared?: boolean
  }
>(({ onClick, iconType, className, squared = false, ...props }, ref) => {
  return (
    <button
      ref={ref}
      className={clsx(
        className,
        classes.commonRoundedButton,
        squared && classes.commonRoundedButtonSquared,
      )}
      onClick={useEventStopPropagation(onClick)}
      {...props}
    >
      <SvgIcon type={iconType} />
    </button>
  )
})
