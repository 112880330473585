import { makeDataField } from '@integration-app/react'
import { buildDataSchema } from '@integration-app/sdk'

import { useGenericConfig } from 'routes/Workspaces/Workspace/components/common-configs/contexts/generic-config-context'

import AdminDataBuilderForm from '../../AdminDataBuilderForm'

/**
 * UI for selecting input for an element inside a node.
 * For example, when we need to select input for a Field Mapping or a Filter.
 */
export function DataInputSelect({ value, onChange }) {
  const { variablesSchema } = useGenericConfig()

  const inputField = makeDataField({
    schema: undefined,
    value,
    variablesSchema: variablesSchema,
  })

  return <AdminDataBuilderForm field={inputField} onChange={onChange} />
}

/**
 * Returns information about data input that can be used in a given element (like Field Mapping or Filter).
 * Goes together with DataInputSelect.
 */
export function useDataInput(value) {
  const { variablesSchema } = useGenericConfig()

  return {
    inputSchema: value
      ? buildDataSchema(value, variablesSchema)
      : variablesSchema,
  }
}
