import { ComponentProps, HTMLAttributes } from 'react'
import {
  TbAlertCircle,
  TbAlertTriangle,
  TbCircleCheck,
  TbInfoCircle,
} from 'react-icons/tb'

import { Alert } from 'components/Alert'

type CallOutTypes = 'info' | 'warning' | 'error' | 'success'

export default function CallOut({
  children,
  type = 'info',
  ...props
}: ComponentProps<typeof Alert.Root> & {
  type?: CallOutTypes
}) {
  return (
    <Alert.Root variant={type} icon={<IconByType type={type} />} {...props}>
      <Alert.Description>{children}</Alert.Description>
    </Alert.Root>
  )
}

function IconByType({
  type,
  ...props
}: HTMLAttributes<SVGElement> & { type: CallOutTypes }) {
  switch (type) {
    case 'info':
      return <TbInfoCircle {...props} />
    case 'success':
      return <TbCircleCheck {...props} />
    case 'warning':
      return <TbAlertCircle {...props} />
    case 'error':
      return <TbAlertTriangle {...props} />
    default:
      return <TbInfoCircle {...props} />
  }
}
