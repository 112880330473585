import { useGenericConfig } from './contexts/generic-config-context'
import { CustomizeImplementation } from './CustomizeImplementation'
import { DataCollectionMethodParametersConfig } from './DataCollectionMethodParametersConfig'
import { DataSourceUnitConfig } from './DataSourceUnitConfig'
import { ImportFieldMappingUnitConfig } from './ImportFieldMappingUnitConfig'
import { RecordIdFieldConfig } from './RecordIdFieldConfig'

export function FindDataRecordByIdUnitConfig() {
  const { dataCollectionSpec } = useGenericConfig()

  const apiRequests = dataCollectionSpec?.findById?.apiRequests

  return (
    <>
      <DataSourceUnitConfig />

      <DataCollectionMethodParametersConfig methodKey='findById' />

      <RecordIdFieldConfig />

      <ImportFieldMappingUnitConfig />

      <CustomizeImplementation apiRequests={apiRequests} />
    </>
  )
}
