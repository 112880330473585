import { PropsWithChildren } from 'react'

import clsx from 'utils/clsx'

import classes from './styles.module.css'

export function DocFullPageMain({ children }: PropsWithChildren) {
  return (
    <div className={clsx(classes.site_main, classes.container)}>{children}</div>
  )
}
