import { makeDataField } from '@integration-app/react'
import { OperationSpec, schemaWithTitle } from '@integration-app/sdk'
import useSWR from 'swr'

import AdminDataBuilderForm from 'components/AdminDataBuilderForm'
import ErrorMessage from 'components/ErrorBoundaries/elements/ErrorMessage'
import { useWorkspace } from 'components/Workspaces/workspace-context'
import { Text } from 'ui-kit/text'

import { useGenericConfig } from './contexts/generic-config-context'
import { getBrokenVariablesConfigurationErrors } from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/getBrokenVariablesConfigurationErrors'
import { NodeConfigurationErrorsGetter } from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/types'

export const getConnectorOperationConfigErrors: NodeConfigurationErrorsGetter =
  ({ config, runTimeVariablesSchema }) => {
    const errors = []

    errors.push(
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2345): Argument of type 'NodeConfigurationErrorData' is n... Remove this comment to see the full error message
      ...getBrokenVariablesConfigurationErrors(config.input, [
        runTimeVariablesSchema,
      ]),
    )

    return errors
  }

export function IntegrationSpecificOperationConfig() {
  const { engineAdminFetcher } = useWorkspace()
  const { config, patchConfig, variablesSchema, integrationId } =
    useGenericConfig()

  const { data: operation, error } = useSWR<OperationSpec>(
    config.operationKey
      ? `/integrations/${integrationId}/operations/${config.operationKey}`
      : null,
    engineAdminFetcher,
  )

  if (!config.operationKey) {
    return <Text m={0}>Operation key note selected.</Text>
  }

  if (error) {
    return (
      <ErrorMessage>
        <ErrorMessage.Header>Something went wrong:</ErrorMessage.Header>
        <ErrorMessage.Content>
          <pre>{error.message}</pre>
        </ErrorMessage.Content>
      </ErrorMessage>
    )
  }

  if (!operation?.inputSchema) {
    return null
  }

  return (
    <AdminDataBuilderForm
      field={makeDataField({
        value: config.input,
        schema: schemaWithTitle(
          operation?.inputSchema ?? { type: 'object' },
          'Input',
        ),
        variablesSchema,
      })}
      onChange={(input) => patchConfig({ input })}
    />
  )
}
