import { ComponentProps, forwardRef, ReactElement, ReactNode } from 'react'

import { Tooltip } from 'components/Tooltip'
import { sva } from 'styled-system/css'
import { Icon } from 'ui-kit/icon'
import * as StyledSwitch from 'ui-kit/styled/switch'
import { SwitchProps as UiKitSwitchProps } from 'ui-kit/switch'
import { Text } from 'ui-kit/text'

type CustomSwitchWithIconProps = {
  icon?: ReactElement
  iconOn?: never
  iconOff?: never
}
type CustomSwitchWithIconsProps = {
  icon?: never
  iconOn: ReactElement
  iconOff: ReactElement
}

type ChildrenNotTooltipProps = {
  children: ReactNode
  tooltip?: never
}

type TooltipNotChildrenProps = {
  children?: never
  tooltip: ReactNode
}

type CustomSwitchProps = CustomSwitchWithIconProps | CustomSwitchWithIconsProps

export type ChildrenOrTooltipProps =
  | ChildrenNotTooltipProps
  | TooltipNotChildrenProps

const tooltipRecipe = sva({
  className: 'switchWithIcon',
  slots: ['tooltipTrigger', 'thumb', 'iconWrapper', 'iconOn', 'iconOff'],
  base: {
    /*
     * The Tooltip Trigger wrapper separates the tooltip component from the switch component.
     * Otherwise, the tooltip would be applied to the switch component, messing up its styles.
     *
     * Wrapper inherits the display property from parent component, the switch component label.
     **/
    tooltipTrigger: {
      display: 'inherit',
    },
    thumb: {
      padding: 0.5,
    },
    iconWrapper: {
      display: 'block',
      position: 'relative',
      width: 'full',
      height: 'full',
      '& svg': {
        position: 'absolute',
        inset: 0,
        gridArea: 'icon',
        width: 'full',
        height: 'full',
      },
    },
    iconOn: {
      opacity: 0,
      '[data-state="checked"] &': {
        opacity: 1,
      },
    },
    iconOff: {
      opacity: 1,
      '[data-state="checked"] &': {
        opacity: 0,
      },
    },
  },
})
const tooltipRecipeClasses = tooltipRecipe()

export type SwitchProps = Omit<UiKitSwitchProps, 'children'> &
  CustomSwitchProps &
  ChildrenOrTooltipProps

export const Switch = forwardRef<HTMLLabelElement, SwitchProps>(
  (props, ref) => {
    const { children, tooltip, iconOn, iconOff, icon, ...rootProps } = props

    const iconOnElement = iconOn || icon
    const iconOffElement = iconOff || icon

    return (
      <StyledSwitch.Root ref={ref} {...rootProps}>
        <SwitchControl tooltip={tooltip}>
          <StyledSwitch.Thumb className={tooltipRecipeClasses.thumb}>
            <Text as='span' className={tooltipRecipeClasses.iconWrapper}>
              {iconOnElement && (
                <Icon className={tooltipRecipeClasses.iconOn}>
                  {iconOnElement}
                </Icon>
              )}
              {iconOffElement && (
                <Icon className={tooltipRecipeClasses.iconOff}>
                  {iconOffElement}
                </Icon>
              )}
            </Text>
          </StyledSwitch.Thumb>
        </SwitchControl>
        {children && <StyledSwitch.Label>{children}</StyledSwitch.Label>}
        <StyledSwitch.HiddenInput />
      </StyledSwitch.Root>
    )
  },
)

Switch.displayName = 'Switch'

export function SwitchControl({
  children,
  tooltip,
  ...props
}: ComponentProps<typeof StyledSwitch.Control> & { tooltip?: ReactNode }) {
  if (tooltip) {
    return (
      <Tooltip tooltip={tooltip}>
        <span className={tooltipRecipeClasses.tooltipTrigger}>
          <StyledSwitch.Control {...props}>{children}</StyledSwitch.Control>
        </span>
      </Tooltip>
    )
  }
  return <StyledSwitch.Control {...props}>{children}</StyledSwitch.Control>
}
