import { ComponentProps, ReactNode } from 'react'

import { Popup } from '../index'

function PopupHeader({
  children,
  ...props
}: ComponentProps<typeof Popup.Header>) {
  return (
    <Popup.Header display='flex' flexDirection='column' gap='2' {...props}>
      {children}
    </Popup.Header>
  )
}

function PopupTitle({
  children,
  leftSlot,
  ...props
}: ComponentProps<typeof Popup.Title> & {
  leftSlot?: ReactNode
}) {
  return (
    <Popup.Title display='flex' alignItems='center' gap='2' {...props}>
      {leftSlot}
      {children}
    </Popup.Title>
  )
}

const CreateSmthPopup = {
  ...Popup,
  Header: PopupHeader,
  Title: PopupTitle,
}
export { CreateSmthPopup }
