import { ReactNode } from 'react'

import { css } from 'styled-system/css'
import { Box, HStack, BoxProps } from 'styled-system/jsx'
import { Button, ButtonProps } from 'ui-kit/button'

export function ButtonWithSlots({
  children,
  leftSlot,
  rightSlot,
  ...props
}: ButtonProps & {
  leftSlot?: ReactNode
  rightSlot?: ReactNode
}) {
  return (
    <Button
      textWrap={'wrap'}
      textAlign={'start'}
      justifyContent={'start'}
      gap={3}
      {...props}
    >
      <HStack width={'full'}>
        <SlotWrapper>{leftSlot}</SlotWrapper>
        {children}
        <SlotWrapper marginInlineStart={'auto'}>{rightSlot}</SlotWrapper>
      </HStack>
    </Button>
  )
}

function SlotWrapper({ children, ...props }: BoxProps) {
  return (
    <Box
      className={css({
        '&:empty': {
          display: 'none',
        },
      })}
      {...props}
    >
      {children}
    </Box>
  )
}
