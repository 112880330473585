import { makeDataField, DataBuilderForm } from '@integration-app/react'
import {
  DataSchema,
  IntegrationElementLevel,
  pickFieldsFromSchema,
  removeRequiredFieldsFromSchema,
  schemaWithTitle,
} from '@integration-app/sdk'

import { ConfigCard } from 'components/Card/ConfigCard'

import { useGenericConfig } from './contexts/generic-config-context'

export function ListFilterConfig() {
  const {
    level,
    dataCollectionSpec,
    udmSpec,
    patchConfig,
    config,
    variablesSchema,
  } = useGenericConfig()

  let filterFieldsSchema: DataSchema | undefined
  let value
  let onChange
  if (level === IntegrationElementLevel.UNIVERSAL) {
    const fieldsSchema = udmSpec?.fieldsSchema
    filterFieldsSchema = schemaWithTitle(
      removeRequiredFieldsFromSchema(
        pickFieldsFromSchema(fieldsSchema, udmSpec?.list?.filterFields),
      ),
      'Filter by Fields',
    )
    value = config.unifiedFilter
    onChange = (unifiedFilter) => patchConfig({ unifiedFilter })
  } else {
    const fieldsSchema = dataCollectionSpec?.fieldsSchema
    filterFieldsSchema = schemaWithTitle(
      removeRequiredFieldsFromSchema(
        pickFieldsFromSchema(
          fieldsSchema,
          dataCollectionSpec?.list?.filterFields,
        ),
      ),
      'Filter by Fields',
    )
    value = config.filter
    onChange = (filter) => patchConfig({ filter })

    filterFieldsSchema = removeRequiredFieldsFromSchema(
      schemaWithTitle(filterFieldsSchema, 'Filter by Fields'),
    )
  }

  const hasFilterFields =
    Object.keys(filterFieldsSchema?.properties || {}).length > 0

  if (!hasFilterFields) {
    return null
  }

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>Filter</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content>
        <DataBuilderForm
          onChange={onChange}
          field={makeDataField({
            variablesSchema,
            value,
            schema: filterFieldsSchema,
          })}
        />
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}
