import { useEffect, useState } from 'react'
import { useWindowEvent } from '@integration-app/ui/hooks/useWindowEvent'
import { useRouter } from 'next/router'
import { InstantSearch, Hits } from 'react-instantsearch'

import { Popup } from 'components/Popup'
import { AlgoliaSidebarSearchPlaceholder } from 'routes/Docs/components/DocsSearch/AlgoliaSearch'
import classes from 'routes/Docs/components/DocsSearch/AlgoliaSearch.module.css'
import { AlgoliaSearchResult } from 'routes/Docs/components/DocsSearch/AlgoliaSearchResult'
import { StylesSearchBox } from 'routes/Docs/components/DocsSearch/StylesSearchBox'
import { searchClient } from 'utils/algolia'

/*
 * Algolia search styling is in globals.css file, because we can't redefine classNames
 */
export function DocsSearchInput() {
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  useWindowEvent('keydown', (event) => {
    if (
      event.key === 'k' &&
      (event.metaKey || event.ctrlKey) &&
      !isSearchOpen
    ) {
      openPopup()
    }
    if (event.key === 'Escape' && isSearchOpen) {
      closePopup()
    }
  })

  const router = useRouter()

  const documentBodyClassList = document.body.classList

  const searchHook = (query: string, search: (query: string) => void) => {
    search(query)
  }

  const openPopup = () => {
    documentBodyClassList.add('overflow-hidden')
    setIsSearchOpen(true)
  }
  const closePopup = () => {
    documentBodyClassList.remove('overflow-hidden')
    setIsSearchOpen(false)
  }

  useEffect(() => {
    closePopup() // close popup on route change
  }, [router.asPath])

  if (!searchClient) {
    return <p>Search is not configured</p>
  }

  return (
    <InstantSearch searchClient={searchClient} indexName='docs'>
      <AlgoliaSidebarSearchPlaceholder onClick={openPopup} />

      <Popup.Root open={isSearchOpen} onOpenChange={closePopup}>
        <Popup.Window
          width={'2xl'}
          height={'3xl'}
          className={classes.popupWrapper}
        >
          <Popup.Header padding='0'>
            <StylesSearchBox
              queryHook={searchHook}
              placeholder='Search'
              autoFocus={true}
            />
          </Popup.Header>

          <Popup.Content padding='0'>
            <Hits hitComponent={AlgoliaSearchResult} />
          </Popup.Content>
        </Popup.Window>
      </Popup.Root>
    </InstantSearch>
  )
}
