import { ReactElement } from 'react'
import { FlowNodeType } from '@integration-app/sdk'

import { NodeStackItemType } from 'components/FlowBuilder/Graph/elements/NodeBody/NodeStackItems/stack-item'

import { ApiRequestToExternalApp as ApiRequestToExternalApp } from './configs/ApiRequestToExternalApp'
import { ApiRequestToYourApp as ApiRequestToYourApp } from './configs/ApiRequestToYourApp'
import { ApiTrigger } from './configs/ApiTrigger'
import { AppEventTrigger } from './configs/AppEventTrigger'
import { ConnectorEventTrigger } from './configs/ConnectorEventTrigger'
import { CreateDataLink } from './configs/CreateDataLink'
import { CustomHttpRequest as CustomHttpRequest } from './configs/CustomHttpRequest'
import { DataRecordCreatedTrigger } from './configs/DataRecordCreatedTrigger'
import { DataRecordDeletedTrigger } from './configs/DataRecordDeletedTrigger'
import { DataRecordUpdatedTrigger } from './configs/DataRecordUpdatedTrigger'
import { DeleteDataLink } from './configs/DeleteDataLink'
import { Filter, getFilterConfigErrors } from './configs/Filter'
import {
  FindDataLink,
  getFindDataLinkConfigErrors,
} from './configs/FindDataLink'
import { FindDataRecords } from './configs/FindDataRecords'
import { FindOrCreateDataRecord } from './configs/FindOrCreateDataRecord'
import { ForEach, getForEachConfigErrors } from './configs/ForEach'
import { ForEachV2 } from './configs/ForEachV2'
import { IntegrationSpecificOperation } from './configs/IntegrationSpecificOperation'
import { RunAction } from './configs/RunAction'
import { RunJavascript } from './configs/RunJavascript'
import { ScheduleTrigger } from './configs/ScheduleTrigger'
import {
  getTransformDataNodeErrors,
  TransformData,
} from './configs/TransformData'
import { NodeConfigurationErrorsGetter } from './configuration-errors/types'
import {
  API_TRIGGER_ICON,
  APP_EVENT_TRIGGER_ICON,
  CREATE_DATA_LINK_ICON,
  CREATE_DATA_RECORD_ICON,
  CUSTOM_API_REQUEST_ICON as API_REQUEST_TO_EXTERNAL_APP_ICON,
  DATA_RECORD_CREATED_TRIGGER_ICON,
  DATA_RECORD_DELETED_TRIGGER_ICON,
  DATA_RECORD_UPDATED_TRIGGER_ICON,
  DELETE_DATA_LINK_ICON,
  DELETE_DATA_RECORD_ICON,
  FILTER_ICON,
  FIND_DATA_LINK_ICON,
  FIND_DATA_RECORD_BY_ID_ICON,
  FIND_DATA_RECORDS_ICON,
  FIND_OR_CREATE_DATA_RECORD_ICON,
  FOR_EACH_ICON,
  HTTP_REQUEST_ICON as HTTP_REQUEST_ICON,
  INTEGRATION_SPECIFIC_OPERATION_ICON,
  LIST_DATA_RECORDS_ICON,
  RUN_ACTION_ICON,
  RUN_JAVASCRIPT_ICON,
  SCHEDULE_TRIGGER_ICON,
  TRANSFORM_DATA_ICON,
  UPDATE_DATA_RECORDS_ICON,
  CONNECTOR_EVENT_TRIGGER_ICON,
} from './icons'
import { getCustomApiRequestConfigErrors as getApiRequestToExternalAppConfigErrors } from '../../../../../../components/common-configs/ApiRequestToExternalAppConfig'
import { CreateDataRecordUnitConfig } from '../../../../../../components/common-configs/CreateDataRecordUnitConfig'
import { getDataLinkConfigErrors } from '../../../../../../components/common-configs/DataLinkConfig'
import { DeleteDataRecordUnitConfig } from '../../../../../../components/common-configs/DeleteDataRecordUnitConfig'
import { getExportFieldMappingConfigErrors } from '../../../../../../components/common-configs/ExportFieldMappingUnitConfig'
import { FindDataRecordByIdUnitConfig } from '../../../../../../components/common-configs/FindDataRecordByIdUnitConfig'
import { getFindOrCreateDataRecordDataLinkConfigErrors } from '../../../../../../components/common-configs/FindOrCreateDataRecordDataLinkConfig'
import { getHttpRequestConfigErrors as getHttpRequestConfigErrors } from '../../../../../../components/common-configs/HttpRequestConfig'
import { getImportFieldMappingConfigErrors } from '../../../../../../components/common-configs/ImportFieldMappingUnitConfig'
import { getConnectorOperationConfigErrors } from '../../../../../../components/common-configs/IntegrationSpecificOperationConfig'
import { ListDataRecordsUnitConfig } from '../../../../../../components/common-configs/ListDataRecordsUnitConfig'
import { getLookupQueryConfigErrors } from '../../../../../../components/common-configs/LookupQueryConfig'
import { MatchDataRecordUnitConfig } from '../../../../../../components/common-configs/MatchDataRecordUnitConfig'
import { getRecordIdConfigErrors } from '../../../../../../components/common-configs/RecordIdFieldConfig'
import { SearchDataRecordsUnitConfig } from '../../../../../../components/common-configs/SearchDataRecordsUnitConfig'
import { UpdateDataRecordUnitConfig } from '../../../../../../components/common-configs/UpdateDataRecordUnitConfig'

export interface ConsoleNodeSpec {
  icon: string

  /**
   * Config Component
   */
  ConfigComponent?: () => ReactElement

  errorsGetters?: NodeConfigurationErrorsGetter[]

  stackItemTypes?: NodeStackItemType[]
}

export const CONSOLE_NODE_SPECS: Record<FlowNodeType, ConsoleNodeSpec> = {
  [FlowNodeType.TransformData]: {
    icon: TRANSFORM_DATA_ICON,
    ConfigComponent: TransformData,
    errorsGetters: [getTransformDataNodeErrors],
  },

  [FlowNodeType.ListDataRecords]: {
    icon: LIST_DATA_RECORDS_ICON,
    ConfigComponent: function () {
      return <ListDataRecordsUnitConfig showPagination={false} />
    },
    errorsGetters: [getImportFieldMappingConfigErrors],
    stackItemTypes: [
      NodeStackItemType.DataSource,
      NodeStackItemType.FieldMapping,
    ],
  },

  [FlowNodeType.SearchDataRecords]: {
    icon: FIND_DATA_RECORDS_ICON,
    ConfigComponent: SearchDataRecordsUnitConfig,
    errorsGetters: [getImportFieldMappingConfigErrors],
    stackItemTypes: [
      NodeStackItemType.DataSource,
      NodeStackItemType.FieldMapping,
    ],
  },

  [FlowNodeType.FindDataRecords]: {
    icon: FIND_DATA_RECORDS_ICON,
    ConfigComponent: FindDataRecords,
    errorsGetters: [
      getImportFieldMappingConfigErrors,
      getLookupQueryConfigErrors,
    ],
    stackItemTypes: [
      NodeStackItemType.DataSource,
      NodeStackItemType.FieldMapping,
    ],
  },

  [FlowNodeType.LookupDataRecord]: {
    icon: FIND_DATA_RECORDS_ICON,
    ConfigComponent: MatchDataRecordUnitConfig,
    errorsGetters: [
      getImportFieldMappingConfigErrors,
      getLookupQueryConfigErrors,
    ],
    stackItemTypes: [
      NodeStackItemType.DataSource,
      NodeStackItemType.FieldMapping,
    ],
  },

  [FlowNodeType.FindOrCreateDataRecord]: {
    icon: FIND_OR_CREATE_DATA_RECORD_ICON,
    ConfigComponent: FindOrCreateDataRecord,
    errorsGetters: [
      getExportFieldMappingConfigErrors,
      getLookupQueryConfigErrors,
      getFindOrCreateDataRecordDataLinkConfigErrors,
    ],
    stackItemTypes: [
      NodeStackItemType.DataSource,
      NodeStackItemType.FieldMapping,
      NodeStackItemType.DataLinkTable,
    ],
  },

  [FlowNodeType.FindDataRecordById]: {
    icon: FIND_DATA_RECORD_BY_ID_ICON,
    ConfigComponent: FindDataRecordByIdUnitConfig,
    errorsGetters: [getImportFieldMappingConfigErrors, getRecordIdConfigErrors],
    stackItemTypes: [
      NodeStackItemType.DataSource,
      NodeStackItemType.FieldMapping,
    ],
  },

  [FlowNodeType.CreateDataRecord]: {
    icon: CREATE_DATA_RECORD_ICON,
    ConfigComponent: CreateDataRecordUnitConfig,
    errorsGetters: [getExportFieldMappingConfigErrors],
    stackItemTypes: [
      NodeStackItemType.DataSource,
      NodeStackItemType.FieldMapping,
    ],
  },

  [FlowNodeType.UpdateDataRecord]: {
    icon: UPDATE_DATA_RECORDS_ICON,
    ConfigComponent: UpdateDataRecordUnitConfig,
    errorsGetters: [getExportFieldMappingConfigErrors, getRecordIdConfigErrors],
    stackItemTypes: [
      NodeStackItemType.DataSource,
      NodeStackItemType.FieldMapping,
    ],
  },

  [FlowNodeType.DeleteDataRecord]: {
    icon: DELETE_DATA_RECORD_ICON,
    ConfigComponent: DeleteDataRecordUnitConfig,
    errorsGetters: [getRecordIdConfigErrors],
    stackItemTypes: [NodeStackItemType.DataSource],
  },

  [FlowNodeType.ApiRequestToYourApp]: {
    icon: HTTP_REQUEST_ICON,
    ConfigComponent: ApiRequestToYourApp,
    errorsGetters: [getHttpRequestConfigErrors],
  },

  [FlowNodeType.ApiRequestToExernalApp]: {
    icon: API_REQUEST_TO_EXTERNAL_APP_ICON,
    ConfigComponent: ApiRequestToExternalApp,
    errorsGetters: [getApiRequestToExternalAppConfigErrors],
  },

  [FlowNodeType.CustomHttpRequest]: {
    icon: HTTP_REQUEST_ICON,
    ConfigComponent: CustomHttpRequest,
    errorsGetters: [getHttpRequestConfigErrors],
  },

  [FlowNodeType.IntegrationSpecificOperation]: {
    icon: INTEGRATION_SPECIFIC_OPERATION_ICON,
    ConfigComponent: IntegrationSpecificOperation,
    errorsGetters: [getConnectorOperationConfigErrors],
  },

  [FlowNodeType.Filter]: {
    icon: FILTER_ICON,
    ConfigComponent: Filter,
    errorsGetters: [getFilterConfigErrors],
  },

  [FlowNodeType.CreateDataLink]: {
    icon: CREATE_DATA_LINK_ICON,
    ConfigComponent: CreateDataLink,
    errorsGetters: [getDataLinkConfigErrors],
  },

  [FlowNodeType.DeleteDataLink]: {
    icon: DELETE_DATA_LINK_ICON,
    ConfigComponent: DeleteDataLink,
    errorsGetters: [getDataLinkConfigErrors],
    stackItemTypes: [NodeStackItemType.DataLinkTable],
  },

  [FlowNodeType.FindDataLink]: {
    icon: FIND_DATA_LINK_ICON,
    ConfigComponent: FindDataLink,
    errorsGetters: [getFindDataLinkConfigErrors],
    stackItemTypes: [NodeStackItemType.DataLinkTable],
  },

  [FlowNodeType.ForEach]: {
    icon: FOR_EACH_ICON,
    ConfigComponent: ForEach,
    errorsGetters: [getForEachConfigErrors],
    stackItemTypes: [],
  },

  [FlowNodeType.ForEachV2]: {
    icon: FOR_EACH_ICON,
    ConfigComponent: ForEachV2,
    errorsGetters: [getForEachConfigErrors],
    stackItemTypes: [],
  },

  [FlowNodeType.RunAction]: {
    icon: RUN_ACTION_ICON,
    ConfigComponent: RunAction,
    errorsGetters: [],
    stackItemTypes: [],
  },

  [FlowNodeType.RunJavascript]: {
    icon: RUN_JAVASCRIPT_ICON,
    ConfigComponent: RunJavascript,
  },

  [FlowNodeType.ScheduleTrigger]: {
    icon: SCHEDULE_TRIGGER_ICON,
    ConfigComponent: ScheduleTrigger,
  },

  [FlowNodeType.ConnectorEventTrigger]: {
    icon: CONNECTOR_EVENT_TRIGGER_ICON,
    ConfigComponent: ConnectorEventTrigger,
  },

  [FlowNodeType.AppEventTrigger]: {
    icon: APP_EVENT_TRIGGER_ICON,
    ConfigComponent: AppEventTrigger,
    errorsGetters: [getFilterConfigErrors],
    stackItemTypes: [NodeStackItemType.AppEventType],
  },

  [FlowNodeType.ApiTrigger]: {
    icon: API_TRIGGER_ICON,
    ConfigComponent: ApiTrigger,
  },

  [FlowNodeType.DataRecordCreatedTrigger]: {
    icon: DATA_RECORD_CREATED_TRIGGER_ICON,
    ConfigComponent: DataRecordCreatedTrigger,
    errorsGetters: [getImportFieldMappingConfigErrors],
    stackItemTypes: [
      NodeStackItemType.DataSource,
      NodeStackItemType.FieldMapping,
    ],
  },

  [FlowNodeType.DataRecordUpdatedTrigger]: {
    icon: DATA_RECORD_UPDATED_TRIGGER_ICON,
    ConfigComponent: DataRecordUpdatedTrigger,
    errorsGetters: [getImportFieldMappingConfigErrors],
    stackItemTypes: [
      NodeStackItemType.DataSource,
      NodeStackItemType.FieldMapping,
    ],
  },

  [FlowNodeType.DataRecordDeletedTrigger]: {
    icon: DATA_RECORD_DELETED_TRIGGER_ICON,
    ConfigComponent: DataRecordDeletedTrigger,
    stackItemTypes: [NodeStackItemType.DataSource],
  },

  // Deprecated
  [FlowNodeType.HttpRequest]: {
    icon: HTTP_REQUEST_ICON,
    ConfigComponent: ApiRequestToYourApp,
    errorsGetters: [getHttpRequestConfigErrors],
  },
  [FlowNodeType.CustomApiRequest]: {
    icon: API_REQUEST_TO_EXTERNAL_APP_ICON,
    ConfigComponent: ApiRequestToExternalApp,
    errorsGetters: [getApiRequestToExternalAppConfigErrors],
  },
  [FlowNodeType.ApiRequest]: {
    icon: HTTP_REQUEST_ICON,
    ConfigComponent: CustomHttpRequest,
    errorsGetters: [getHttpRequestConfigErrors],
  },
}
