import QueryString from 'query-string'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useFlowBuilder } from 'components/FlowBuilder/context/flow-builder-context'
import { useIsInRoute } from 'components/SPA'
import { Button } from 'ui-kit/button'
import clsx from 'utils/clsx'

import classes from '../styles.module.css'

export function AddTriggerButton({
  child = '',
  dark = false,
}: {
  dark?: boolean
  child: string
}) {
  const { isActive, start } = useAddingTrigger(child)

  return (
    <Button
      variant={dark ? 'solid' : 'subtle'}
      className={clsx(classes.button, isActive && classes.buttonActive)}
      onClick={start}
    >
      Add Trigger
    </Button>
  )
}

function useAddingTrigger(child) {
  const navigate = useNavigate()
  const { baseRoute } = useFlowBuilder()

  const isInRoute = useIsInRoute()
  const [searchParams] = useSearchParams()

  const query = Object.fromEntries(searchParams.entries()) as {
    child: string
  }

  const isActive = isInRoute('add-trigger') && query.child === child

  function start() {
    navigate({
      pathname: `${baseRoute}/add-trigger`,
      search: QueryString.stringify({ child }),
    })
  }

  return {
    isActive,
    start,
  }
}
