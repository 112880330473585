import { createContext, ReactNode, useContext } from 'react'
import { Flow } from '@integration-app/sdk'

export const FlowPreviewProvider = ({
  children,
  flowNodes,
}: {
  children: ReactNode
  flowNodes: Flow['nodes']
}) => {
  const context = {
    flowNodes,
  }

  return (
    <FlowPreviewContext.Provider value={context}>
      {flowNodes && children}
    </FlowPreviewContext.Provider>
  )
}

const FlowPreviewContext = createContext<{
  flowNodes: Flow['nodes']
}>({
  flowNodes: {},
})

export function useFlowPreviewContext() {
  return useContext(FlowPreviewContext)
}
