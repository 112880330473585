import { Cell, Row } from '@integration-app/react'

import { UdmComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/UdmComboboxSelect'

export default function UdmSelect({
  value,
  onChange,
}: {
  value?: string
  onChange: (value?: string) => void
}) {
  return (
    <Row>
      <Cell.Name>Data Model</Cell.Name>
      <Cell.Combobox grow>
        <UdmComboboxSelect
          value={value}
          onChange={onChange}
          variant='ghost'
          placeholder='Select Universal Data Model'
          my={1}
        />
      </Cell.Combobox>
    </Row>
  )
}
